import { faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';
import type { JSX } from 'react';
import React from 'react';

import type { FieldDataType } from '@feathr/blackbox';
import { Button, Icon } from '@feathr/components';

import { typeToIcon } from './Field.utils';

import * as styles from './Field.css';

interface IField {
  type: FieldDataType;
  label: string;
}

interface IProps {
  field: IField;
}

function Field({ field: { type, label } }: IProps): JSX.Element {
  return (
    <div className={styles.root}>
      <Button
        className={styles.container}
        prefix={<Icon className={styles.grip} icon={faGripDotsVertical} />}
      >
        <div className={styles.content}>
          <Icon className={styles.icon} icon={typeToIcon(type)} size={6} />
          <span className={styles.label}>{label}</span>
        </div>
      </Button>
    </div>
  );
}

export default Field;
