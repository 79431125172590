import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IDripCampaignStepSpec } from '@feathr/blackbox';
import { ButtonValid, CardV2 as Card, Label } from '@feathr/components';
import { flattenErrors } from '@feathr/hooks';

import AutomationSchedule from '../../../AutomationSchedule/AutomationSchedule';
import MergeDataInputs from '../../../EmailDetails/MergeDataInputs';
import PinpointTriggersConfig from '../../../PinpointActions/PinpointTriggersConfig';
import TriggerTypeRadios from '../../../TriggerTypeRadios/TriggerTypeRadios';
import useTriggerTypeRadios from '../../../TriggerTypeRadios/useTriggerTypeRadios';
import { validateInitialEnrollment } from '../../DripCampaignEdit.utils';
import EmailInputs from '../EmailInputs';
import useEphemeralCampaign from '../useEphemeralCampaign';
import useSyncStepsWithStepSpecs from '../useSyncStepsWithStepSpecs';

import * as styles from './EditInitialEnrollment.css';

interface IEditInitialEnrollmentProps {
  dripCampaignId: string;
  step: IDripCampaignStepSpec;
  onApply: (updatedStep: IDripCampaignStepSpec) => Promise<void>;
  onSync: (updatedStep: IDripCampaignStepSpec) => Promise<void>;
  onCancel: () => void;
  unverifiedFromAddresses: string[];
}

function EditInitialEnrollment({
  dripCampaignId,
  step,
  onApply,
  onSync,
  onCancel,
  unverifiedFromAddresses: unverified,
}: Readonly<IEditInitialEnrollmentProps>): JSX.Element {
  const { t } = useTranslation();

  const { campaign, handleApply, handleSync } = useEphemeralCampaign({
    dripCampaignId,
    onApply,
    onSync,
    onCancel,
    step,
  });

  const { handleSubtypeChange, repeat, setRepeat, subtype, triggerTypeOptions } =
    useTriggerTypeRadios(campaign);
  useSyncStepsWithStepSpecs({ ephemeralCampaign: campaign, handleSync });

  const validationErrors = validateInitialEnrollment({
    campaign,
    includeName: false,
    isVerifiedSender:
      unverified && step.from_address ? !unverified.includes(step.from_address) : undefined,
  });

  return (
    <Card theme={'disabled'} width={'full'}>
      <Card.Content addVerticalGap={true}>
        <div className={styles.overrideGap}>
          <Label margin={'condensed'} weight={'semibold'}>
            {t('Initial enrollment')}
          </Label>
          <p>{t('Select the type of trigger you want to use for this drip campaign.')}</p>
        </div>
        <TriggerTypeRadios
          context={'drip'}
          description={t('Select the type of trigger you want to use for this drip campaign.')}
          disabled={false}
          handleSubtypeChange={handleSubtypeChange}
          subtype={subtype}
          title={t('Trigger type')}
          triggerTypeOptions={triggerTypeOptions}
        />
        <PinpointTriggersConfig campaign={campaign} context={'drip'} disabled={false} />
        <AutomationSchedule
          campaign={campaign}
          context={'drip'}
          disabled={false}
          repeat={repeat}
          setRepeat={setRepeat}
          validationErrors={validationErrors}
        />
      </Card.Content>
      <Card.Content>
        <Label weight={'semibold'}>{t('Email')}</Label>
        <EmailInputs campaign={campaign} validationErrors={validationErrors} />
      </Card.Content>
      <Card.Content>
        <Label margin={'condensed'} weight={'semibold'}>
          {t('Default Merge Data')}
        </Label>
        <p>
          {t(
            'These values will be used to replace merge tags when the recipient does not have data for the corresponding field.',
          )}
        </p>
        <MergeDataInputs campaign={campaign} disabled={false} />
      </Card.Content>
      <Card.Actions>
        {/* TODO: Bring back when we can be sure cancel works properly with sync */}
        {/* <Button name={'step-1-cancel'} onClick={handleCancel}>
          {t('Cancel')}
        </Button> */}
        <ButtonValid
          errors={flattenErrors(validationErrors)}
          name={'step-1-apply'}
          onClick={handleApply}
          type={'primary'}
        >
          {t('Apply')}
        </ButtonValid>
      </Card.Actions>
    </Card>
  );
}

export default observer(EditInitialEnrollment);
