import type { TConstraints } from '@feathr/rachis';

import { AutoPinpointEmailBaseCampaign } from './auto_pinpoint_email_base';
import type { IAutoPinpointEmailCampaign } from './types';

export class AutoPinpointEmailCampaign extends AutoPinpointEmailBaseCampaign<IAutoPinpointEmailCampaign> {
  public override get constraints(): TConstraints<IAutoPinpointEmailCampaign> {
    return super.constraints;
  }
}
