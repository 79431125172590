import { computed } from 'mobx';

import { concatPath } from '@feathr/hooks';
import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { Collection, DisplayModel, isWretchError, wretch } from '@feathr/rachis';

export enum ECustomerStatus {
  UNSPECIFIED = 'UNSPECIFIED',
  UNKNOWN = 'UNKNOWN',
  ENABLED = 'ENABLED',
  CANCELLED = 'CANCELLED',
  SUSPENDED = 'SUSPENDED',
  CLOSED = 'CLOSED',
}

export enum EConnectionStatus {
  connected = 'connected',
  disconnected = 'disconnected',
  pending = 'pending',
}

/** If the customer is a paid google ads user, ad grant user or unknown */
export enum EAdGrantStatus {
  unknown = 'unknown',
  ad_grant = 'ad_grant',
  paid = 'paid',
}

export interface IGoogleAdsCustomerAttributes extends IBaseAttributes {
  is_archived: boolean;
  account: string;
  ad_grant_status: EAdGrantStatus;
  customer_id: string;
  date_created: string;
  date_integrated: string | null;
  descriptive_name: string | null;
  manager: boolean | null;
  customer_status: ECustomerStatus;
  client_link: string;
  date_invite_sent: string;
  invite_status: EConnectionStatus;
}

interface IRefreshProps {
  customerId: string;
}

interface ILinkCustomerProps extends IRefreshProps {
  action?: string;
}

export class GoogleAdsCustomer extends DisplayModel<IGoogleAdsCustomerAttributes> {
  public readonly className = 'GoogleAdsCustomer';

  public get constraints(): TConstraints<IGoogleAdsCustomerAttributes> {
    return {
      customer_id: {
        presence: { allowEmpty: false },
        format: {
          pattern: /^(\d{10}|\d{3}-\d{3}-\d{4})$/,
          flags: 'i',
          message: 'Must be a valid customer account or ID number',
        },
      },
    };
  }

  public getItemUrl(pathSuffix?: string | undefined): string {
    return concatPath(`/settings/integrations/google-ads/${this.id}`, pathSuffix);
  }

  public async refresh({ customerId }: IRefreshProps): Promise<IGoogleAdsCustomerAttributes> {
    this.assertCollection(this.collection);

    const response = await wretch<IGoogleAdsCustomerAttributes>(this.collection.url(), {
      method: 'POST',
      body: JSON.stringify({ customer_id: customerId }),
      headers: this.collection.getHeaders(),
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }

  public get name(): string {
    return this.get('name', '').trim() || 'Unnamed Google Ads Customer';
  }

  @computed
  public get isConnected(): boolean {
    return this.get('invite_status') === EConnectionStatus.connected;
  }

  @computed
  public get isDisconnected(): boolean {
    return this.get('invite_status') === EConnectionStatus.disconnected;
  }

  @computed
  public get isInvitePending(): boolean {
    return this.get('invite_status') === EConnectionStatus.pending;
  }
}

export class GoogleAdsCustomers extends Collection<GoogleAdsCustomer> {
  public getModel(attributes: IGoogleAdsCustomerAttributes): GoogleAdsCustomer {
    return new GoogleAdsCustomer(attributes);
  }

  public getClassName(): string {
    return 'google_ads_customers';
  }

  // Variant must be optional so we can fall back to the customer url for Collection.list
  public override url(variant?: 'link'): string {
    const path = `${this.getHostname()}integrations/google-ads/account/`;

    if (variant === 'link') {
      return `${path}link`;
    }

    return `${path}customer`;
  }

  public async linkCustomer({
    customerId,
    action = 'link',
  }: ILinkCustomerProps): Promise<IGoogleAdsCustomerAttributes> {
    const url = this.url('link');

    const response = await wretch<IGoogleAdsCustomerAttributes>(url, {
      method: 'POST',
      body: JSON.stringify({ customer_id: customerId, action }),
      headers: this.getHeaders(),
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }
}
