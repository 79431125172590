import { faInfoCircle, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign } from '@feathr/blackbox';
import {
  Button,
  CardActions,
  CardV2 as Card,
  Chip,
  Collapse,
  Fieldset,
  Input,
  Radios,
} from '@feathr/components';
import EditFilters from '@feathr/extender/components/EditFilters';
import { StoresContext } from '@feathr/extender/state';

import type {
  ISegmentSelectSimpleChangeProps,
  ISegmentSelectSimpleProps,
} from '../SegmentSelectSimple';
import SegmentSelectSimple from '../SegmentSelectSimple';
import FacebookAudience from './FacebookAudience';

import * as styles from './SegmentSelect.css';

export interface ISegmentSelectChangeProps extends ISegmentSelectSimpleChangeProps {
  id: string;
  included: boolean;
  disableInclusion?: boolean;
  disabled?: boolean;
}

interface ISegmentSelectBaseProps extends Omit<ISegmentSelectSimpleProps, 'onChange'> {
  children?: ReactNode;
  /** Child elements to display when adding a new group. */
  campaign?: Campaign;
  childrenOnAdd?: ReactNode;
  onRemove?: (id?: string) => void;
  prefix?: ReactNode;
}

export interface ISegmentSelectWithIncludeProps extends ISegmentSelectBaseProps {
  hideIncluded?: boolean;
  included: boolean;
  onChange: (props: ISegmentSelectChangeProps) => void;
}

export interface ISegmentSelectWithoutIncludeProps extends ISegmentSelectBaseProps {
  hideIncluded: true;
  included?: never;
  onChange: (props: ISegmentSelectSimpleChangeProps) => void;
}

function isWithIncludeProps(
  props: ISegmentSelectWithoutIncludeProps | ISegmentSelectWithIncludeProps,
): props is ISegmentSelectWithIncludeProps {
  return typeof (props as ISegmentSelectWithIncludeProps).included === 'boolean';
}

function SegmentSelect(props: ISegmentSelectWithoutIncludeProps);
function SegmentSelect(props: ISegmentSelectWithIncludeProps);
function SegmentSelect(
  props: ISegmentSelectWithoutIncludeProps | ISegmentSelectWithIncludeProps,
): JSX.Element {
  const {
    campaign,
    children,
    childrenOnAdd,
    filters = [],
    disableInclusion = false,
    disabled = false,
    excludeIds = [],
    hideCreate = false,
    hideIncluded = false,
    includeIds = [],
    included,
    onChange,
    onCreate,
    onRemove,
    prefix,
    stat,
    name,
    value,
  } = props;

  const { Segments } = useContext(StoresContext);
  const { t } = useTranslation();

  const segment = value ? Segments.get(value) : undefined;

  function handleRemove(): void {
    // This function is only callable if onRemove is set.
    onRemove!(value);
  }

  function handleSegmentChange({
    id,
  }: ISegmentSelectSimpleChangeProps | ISegmentSelectChangeProps): void {
    if (isWithIncludeProps(props)) {
      props.onChange({
        id,
        oldId: value,
        included: included!,
      });
    } else {
      props.onChange({ id, oldId: value });
    }
  }

  function handleIncludeChange(newValue?: string): void {
    onChange({
      id: value,
      oldId: value,
      included: newValue === 'included',
    });
  }

  return (
    <Card contentClassName={styles.tempFix} name={'group'}>
      <Card.Content>
        {prefix}
        <Fieldset className={styles.fieldset}>
          <SegmentSelectSimple
            disabled={disabled}
            excludeIds={excludeIds}
            filters={filters}
            hideCreate={hideCreate}
            includeIds={includeIds}
            label={'Group'}
            name={name}
            onChange={handleSegmentChange}
            onCreate={onCreate}
            required
            stat={stat}
            value={value}
          />
          {segment?.isEphemeral && (
            <>
              <Input
                attribute={'name'}
                disabled={disabled}
                label={t('Name')}
                model={segment}
                required={true}
                type={'text'}
              />
              {childrenOnAdd}
            </>
          )}
          {!hideIncluded && (
            <Radios
              dataName={'filter_mode'}
              disabled={disableInclusion || disabled}
              onChange={handleIncludeChange}
              options={[
                { id: 'included', name: t('Included') },
                { id: 'excluded', name: t('Excluded') },
              ]}
              value={included ? 'included' : 'excluded'}
              wrapperClassName={styles.radios}
            />
          )}
          {campaign?.isFacebook && (
            <FacebookAudience campaignState={campaign.get('state')} segment={segment} />
          )}
        </Fieldset>
        {segment && (
          <Collapse
            title={
              segment.get('read_only') ? t('View group filters') : t('Configure group filters')
            }
          >
            {segment.get('read_only') && (
              <Chip
                suffix={<FontAwesomeIcon icon={faInfoCircle} />}
                tooltip={t(
                  'This group cannot be edited because it is based upon a conversion pixel.',
                )}
              >
                {t('Read-only')}
              </Chip>
            )}
            <EditFilters
              disabled={segment.get('read_only') || disabled}
              filterContext={filters.predicates__elemMatch ? 'facebookCampaign' : 'campaign'}
              hideGauge={true}
              mode={stat === 'num_emails_total' ? 'emails' : 'reachable'}
              segment={segment}
            />
          </Collapse>
        )}
        {children}
      </Card.Content>
      {onRemove && (
        <CardActions>
          <Button
            disabled={disabled}
            name={'remove_segment'}
            onClick={handleRemove}
            prefix={<FontAwesomeIcon icon={faTrash} />}
            type={'dangerSecondary'}
          >
            {t('Remove')}
          </Button>
        </CardActions>
      )}
    </Card>
  );
}

export default observer(SegmentSelect);
