import { moment } from '@feathr/hooks';
import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { Collection, Model } from '@feathr/rachis';

import { EIntegrationTypes } from './integrations';

export enum TargetableClass {
  email_list = 'Targetable.EmailListTargetable',
  geo = 'Targetable.GeoTargetable',
  factual = 'Targetable.FactualTargetable',
  lookalike = 'Targetable.LookalikeTargetable',
  seed_segment = 'Targetable.SeedSegmentTargetable',
  affinity = 'Targetable.AffinityTargetable',
  search = 'Targetable.SearchTargetable',
  segment = 'PersonSegment',
  google_ads_geo = 'Targetable.GoogleGeoTargetable',
}

export const Categories = [
  {
    label: 'Industry',
    options: [
      { id: 468, name: 'Industry' },
      { id: 473, name: 'Industry / Agriculture & Forestry' },
      { id: 474, name: 'Industry / Arts & Entertainment' },
      { id: 475, name: 'Industry / Automotive' },
      { id: 476, name: 'Industry / Business & Finance' },
      { id: 477, name: 'Industry / Computers & Technology' },
      { id: 478, name: 'Industry / Construction' },
      { id: 479, name: 'Industry / Consumer Services' },
      { id: 480, name: 'Industry / Education' },
      { id: 481, name: 'Industry / Government' },
      { id: 482, name: 'Industry / Health Care' },
      { id: 483, name: 'Industry / Manufacturing' },
      { id: 484, name: 'Industry / Real Estate' },
      { id: 485, name: 'Industry / Retail & Shopping' },
      { id: 486, name: 'Industry / Services' },
      { id: 488, name: 'Industry / Travel' },
      { id: 508, name: 'Industry / Small Business' },
      { id: 490, name: 'Industry / Occupation' },
      { id: 491, name: 'Industry / Occupation / Accounting & Finance' },
      { id: 492, name: 'Industry / Occupation / Administrative' },
      { id: 493, name: 'Industry / Occupation / Agriculture & Manufacturing' },
      { id: 494, name: 'Industry / Occupation / Blue Collar' },
      { id: 495, name: 'Industry / Occupation / Business Owner' },
      { id: 496, name: 'Industry / Occupation / Construction' },
      { id: 497, name: 'Industry / Occupation / Education' },
      { id: 498, name: 'Industry / Occupation / Engineering' },
      { id: 499, name: 'Industry / Occupation / Government' },
      { id: 500, name: 'Industry / Occupation / Health' },
      { id: 501, name: 'Industry / Occupation / Job Search' },
      { id: 502, name: 'Industry / Occupation / Legal' },
      { id: 503, name: 'Industry / Occupation / Management' },
      { id: 504, name: 'Industry / Occupation / Military' },
      { id: 505, name: 'Industry / Occupation / Sales & Marketing' },
      { id: 506, name: 'Industry / Occupation / Technical' },
      { id: 507, name: 'Industry / Occupation / White Collar' },
    ],
  },
  {
    label: 'Demographic',
    options: [
      { id: 524, name: 'Demographic' },
      { id: 532, name: 'Demographic / Education' },
      { id: 533, name: 'Demographic / Education / Area Of Study' },
      { id: 534, name: 'Demographic / Education / Colleges & Universities' },
      { id: 535, name: 'Demogrphic / Education / Level Of Education' },
    ],
  },
  {
    label: 'In-Market',
    options: [
      { id: 600, name: 'In-Market' },
      { id: 601, name: 'In-Market / Arts & Entertainment' },
      { id: 602, name: 'In-Market / Arts & Entertainment / Events' },
      { id: 603, name: 'In-Market / Arts & Entertainment / Music' },
      { id: 604, name: 'In-Market / Arts & Entertainment / Tv & Movies' },
      { id: 605, name: 'In-Market / Autos' },
      { id: 606, name: 'In-Market / Autos / Auto Parts' },
      { id: 607, name: 'In-Market / Autos / Boats' },
      { id: 647, name: 'In-Market / Autos / Class / Economical' },
      { id: 648, name: 'In-Market / Autos / Class / Full-Size' },
      { id: 649, name: 'In-Market / Autos / Class / Hybrid' },
      { id: 650, name: 'In-Market / Autos / Class / Mid-Size' },
      { id: 651, name: 'In-Market / Autos / Class / Other' },
      { id: 652, name: 'In-Market / Autos / Class / Sports Cars' },
      { id: 653, name: 'In-Market / Autos / Class / Trucks & Suvs' },
      { id: 654, name: 'In-Market / Autos / Class / Vans' },
      { id: 655, name: 'In-Market / Autos / Finance & Insurance' },
      { id: 656, name: 'In-Market / Autos / Motorcycles' },
      { id: 657, name: 'In-Market / Autos / Used' },
      { id: 659, name: 'In-Market / Beauty' },
      { id: 660, name: 'In-Market / Beauty / Cosmetics' },
      { id: 661, name: 'In-Market / Beauty / Hair Care' },
      { id: 662, name: 'In-Market / Beauty / Personal Care' },
      { id: 663, name: 'In-Market / Beauty / Skin Care' },
      { id: 664, name: 'In-Market / Books & Literature' },
      { id: 665, name: 'In-Market / Business' },
      { id: 666, name: 'In-Market / Computers & Technology' },
      { id: 667, name: 'In-Market / Computers & Technology / Audio & Video' },
      { id: 669, name: 'In-Market / Computers & Technology / Cameras & Camcorders' },
      { id: 670, name: 'In-Market / Computers & Technology / Cell Phones' },
      { id: 671, name: 'In-Market / Computers & Technology / Computers' },
      { id: 672, name: 'In-Market / Computers & Technology / Tv & Home Theater' },
      { id: 673, name: 'In-Market / Education' },
      {
        id: 674,
        name: 'In-Market / Education / Area Of Study',
      },
      { id: 675, name: 'In-Market / Education / School Location' },
      {
        id: 676,
        name: 'In-Market / Fashion & Style',
      },
      {
        id: 677,
        name: 'In-Market / Fashion & Style / Accessories',
      },
      {
        id: 679,
        name: 'In-Market / Fashion & Style / Clothing',
      },
      { id: 690, name: 'In-Market / Fashion & Style / Footwear' },
      { id: 691, name: 'In-Market / Fashion & Style / Jewelry' },
      {
        id: 692,
        name: 'In-Market / Finance',
      },
      {
        id: 713,
        name: 'In-Market / Games',
      },
      { id: 715, name: 'In-Market / Health' },
      { id: 716, name: 'In-Market / Hobbies & Leisure' },
      {
        id: 717,
        name: 'In-Market / Home & Garden',
      },
      {
        id: 718,
        name: 'In-Market / Home & Garden / Bed & Bath',
      },
      { id: 719, name: 'In-Market / Home & Garden / Home Furnishing' },
      { id: 720, name: 'In-Market / Home & Garden / Home Improvement' },
      {
        id: 722,
        name: 'In-Market / Home & Garden / Kitchen & Appliances',
      },
      { id: 723, name: 'In-Market / Home & Garden / Outdoor & Patio' },
      {
        id: 724,
        name: 'In-Market / Outdoor & Recreation',
      },
      {
        id: 725,
        name: 'In-Market / Outdoor & Recreation / Camping & Hiking',
      },
      { id: 726, name: 'In-Market / Outdoor & Recreation / Cycling' },
      { id: 727, name: 'In-Market / Outdoor & Recreation / Fishing & Game' },
      { id: 728, name: 'In-Market / Pets & Animals' },
      {
        id: 743,
        name: 'In-Market / Real Estate',
      },
      {
        id: 745,
        name: 'In-Market / Retail & Shopping',
      },
      {
        id: 754,
        name: 'In-Market / Sports',
      },
      { id: 755, name: 'In-Market / Sports / Individual Sports' },
      { id: 756, name: 'In-Market / Sports / Sports Brands' },
      { id: 757, name: 'In-Market / Sports / Water Sports' },
      { id: 758, name: 'In-Market / Sports / Winter Sports' },
      {
        id: 759,
        name: 'In-Market / Travel',
      },
      {
        id: 760,
        name: 'In-Market / Travel / Car Rentals',
      },
      { id: 762, name: 'In-Market / Travel / Cruises' },
      {
        id: 779,
        name: 'In-Market / Travel / Flights',
      },
      {
        id: 783,
        name: 'In-Market / Travel / Hotels',
      },
      {
        id: 789,
        name: 'In-Market / Travel / Vacation',
      },
    ],
  },
];

export interface IGeoFencingPlace {
  id: string;
  lat: number;
  lng: number;
  name?: string;
}

export interface IGoogleAdsLocationInfo {
  geo_target_constant: string;
}

export interface IGoogleAdsProximityInfo {
  radius: number;
  radius_units: string;
  lat: number;
  long: number;
}

// TODO: refactor ITargetable into smaller, more digestible interfaces
export interface ITargetable extends IBaseAttributes {
  _cls: TargetableClass;
  name: string;
  // Geofilter
  geo_filter?: string;
  // Lookalike
  mode?: 'auto' | 'manual';
  // Seed_segment
  seed_segment?: string;
  // Affinity
  thirdparty_data_id?: string;
  // Affinity
  thirdparty_brand_name?: string;
  // Affinity
  thirdparty_data_name?: string;
  // Affinity
  full_path?: string;
  // Affinity
  unique_user_count?: number;
  // Affinity
  category_id?: number;
  // Affinity
  description?: string;
  // Affinity
  audience_inactive?: boolean;
  // Search
  search_keywords?: string[];
  src_search_keywords?: string;
  src_filename?: string;
  num_keywords?: number;
  // Email list
  list_file_name?: string;
  list_file?: string;
  location_info?: IGoogleAdsLocationInfo;
  num_unique_emails?: number;
  // Factual
  is_historical?: boolean;
  date_start?: string;
  date_end?: string;
  places?: IGeoFencingPlace[];
  units: 'kilometers' | 'meters';
  radius: number;
  file_name?: string;
  file_url?: string;
  integrations?: EIntegrationTypes[];
  partner?: string;
}

export class Targetable extends Model<ITargetable> {
  public static defaults: Partial<ITargetable> = {
    integrations: [EIntegrationTypes.Tradedesk],
  };
  public readonly className = 'Targetable';
  public get constraints(): TConstraints<ITargetable> {
    return {
      _cls: {
        inclusion: Object.values(TargetableClass),
      },
      mode(...args: any[]) {
        const { _cls } = args[1];
        if (_cls === TargetableClass.lookalike) {
          return { inclusion: ['auto', 'manual'] };
        }
        return undefined;
      },
      thirdparty_data_id(...args: any[]) {
        const { _cls, mode } = args[1];
        /*
         * Constraint for lookalike manual mode to remain until Lookalike Campaigns are deprecated
         * only affinity will have thirdparty_data_id
         * TODO: remove 'lookalike OR' check when Lookalike Campaigns are deprecated
         */
        if (
          (_cls === TargetableClass.lookalike && mode === 'manual') ||
          _cls === TargetableClass.affinity
        ) {
          return {
            presence: {
              allowEmpty: false,
              message: '^Please choose an affinity audience to set for your Target.',
            },
          };
        }
        return undefined;
      },
      seed_segment(...args: any[]) {
        const { _cls, mode } = args[1];
        /*
         * Constraint for lookalike auto mode to remain until Lookalike Campaigns are deprecated
         * only seed_segment will have seed_segment field
         * TODO: remove 'lookalike OR' check when Lookalike Campaigns are deprecated
         */
        if (
          (_cls === TargetableClass.lookalike && mode === 'auto') ||
          _cls === TargetableClass.seed_segment
        ) {
          return {
            presence: {
              allowEmpty: false,
              message: '^Please add a seed group to your target.',
            },
          };
        }
        return undefined;
      },
      list_file(...args: any[]) {
        const { _cls } = args[1];
        if (_cls === TargetableClass.email_list) {
          return {
            presence: {
              allowEmpty: false,
              message: '^Please upload an email list with at least 2,500 unique email addresses.',
            },
          };
        }
        return undefined;
      },
      src_filename(...args: any[]) {
        const { _cls } = args[1];
        if (_cls === TargetableClass.search) {
          return {
            presence: {
              allowEmpty: false,
              message: '^Please upload a keyword list.',
            },
          };
        }
        return undefined;
      },
      num_keywords(...args: any[]) {
        const { _cls } = args[1];
        if (_cls === TargetableClass.search) {
          return {
            numericality: {
              greaterThanOrEqualTo: 1,
              notGreaterThanOrEqualTo: '^Please upload a keyword list with at least 1 search term.',
            },
          };
        }
        return undefined;
      },
      places(...args: any[]) {
        const { _cls } = args[1];
        if (_cls === TargetableClass.factual) {
          return {
            length: {
              minimum: 1,
              message: '^Please add a place to your target.',
            },
          };
        }
        return null;
      },
      radius(...args: any[]) {
        const { radius, units } = args[1];
        if (radius < 1000) {
          return {
            numericality: {
              greaterThan: 1000,
              message:
                units === 'meters'
                  ? '^Radius must be greater than 1,000 meters.'
                  : '^Radius must be greater than 1 kilometers.',
            },
          };
        }
        if (radius > 30000) {
          return {
            numericality: {
              lessThan: 30000,
              message:
                units === 'meters'
                  ? '^Radius must be less than 30,000 meters.'
                  : '^Radius must be less than 30 kilometers.',
            },
          };
        }
        return undefined;
      },
      date_start(...args: any[]) {
        const { _cls, date_end, is_historical } = args[1];
        if (_cls === TargetableClass.factual && !!is_historical) {
          return {
            presence: {
              allowEmpty: false,
              message: '^Please choose a start date for your target',
            },
            datetime: {
              latest: moment.utc(date_end).subtract(2, 'days'),
              message: '^Start date must be at least two days earlier than the end date.',
              dateOnly: true,
            },
          };
        }
        return undefined;
      },
      date_end(...args: any[]) {
        const { _cls, is_historical } = args[1];
        if (_cls === TargetableClass.factual && !!is_historical) {
          return {
            presence: {
              allowEmpty: false,
              message: '^Please choose an end date for your target',
            },
            datetime: {
              latest: moment().subtract(30, 'days'),
              message: '^End date must be at least 30 days in the past.',
              dateOnly: true,
            },
          };
        }
        return undefined;
      },
      is_historical(...args: any[]) {
        const { _cls } = args[1];
        if (_cls === TargetableClass.factual) {
          return {
            presence: {
              allowEmpty: true,
              message: '^Please choose an end date for your target',
            },
          };
        }
        return undefined;
      },
    };
  }

  public override getDefaults(): Partial<ITargetable> {
    return Targetable.defaults;
  }
}

export class Targetables extends Collection<Targetable> {
  public getClassName(): string {
    return 'targetables';
  }

  public getModel(attributes: Partial<ITargetable>): Targetable {
    return new Targetable(attributes);
  }
}
