import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faCalendar,
  faCheckSquare,
  faHashtag,
  faListDropdown,
  faText,
} from '@fortawesome/pro-regular-svg-icons';

import { FieldDataType } from '@feathr/blackbox';

export function typeToIcon(type: FieldDataType): IconDefinition {
  const map = {
    [FieldDataType.str]: faText,
    [FieldDataType.int]: faHashtag,
    [FieldDataType.float]: faHashtag,
    [FieldDataType.bool]: faCheckSquare,
    [FieldDataType.date]: faCalendar,
    [FieldDataType.list]: faListDropdown,
  };

  return map[type];
}
