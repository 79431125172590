import { faUsers } from '@fortawesome/pro-regular-svg-icons';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { SingleValueProps } from 'react-select';
import { components } from 'react-select';

import { Segment } from '@feathr/blackbox';
import { Label } from '@feathr/components';

import SegmentDataChip from '../SegmentDataChip';
import type { TStat } from '../SegmentOption';

import * as styles from './SegmentSingleValue.css';

type TSingleValueProps = SingleValueProps<Segment>;

interface IDataProps extends TSingleValueProps {
  selectProps: {
    stat?: TStat;
    isLoading: boolean;
    isDisabled: boolean;
  };
  data: Segment;
}

function SegmentSingleValue(props: TSingleValueProps): JSX.Element {
  const { t } = useTranslation();
  const {
    data,
    selectProps: { stat, isLoading, isDisabled },
  } = props as IDataProps;

  return (
    <components.SingleValue {...props}>
      <Observer>
        {(): JSX.Element => (
          <Label className={styles.label} disabled={isDisabled} weight={'normal'}>
            {data.name}
          </Label>
        )}
      </Observer>

      {/* If the options are loading, don't show the data chips so the loading ellipsis can be displayed. Otherwise, the chips shift abruptly on load. */}
      {data instanceof Segment && !isLoading && (
        <div className={styles.data}>
          <SegmentDataChip
            descriptor={t('people')}
            icon={faUsers}
            statType={stat}
            statValue={data.get('stats').num_users_total || 0}
            theme={'sky'}
          />
          {!!stat && <SegmentDataChip statType={stat} statValue={data.get('stats')[stat] || 0} />}
        </div>
      )}
    </components.SingleValue>
  );
}

export default SegmentSingleValue;
