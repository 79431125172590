import type { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import type { IRedirectDomain } from '@feathr/blackbox';
import type { Event } from '@feathr/blackbox';
import {
  ButtonValid,
  DatePicker,
  Fieldset,
  Form,
  ImagePicker,
  Input,
  Select,
  toast,
} from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import TagsField from '@feathr/extender/components/TagsField';
import { StoresContext, useAccount, useLocalUrl } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';

const SaveButton = observer(({ event, t }: { event: Event; t: TFunction }) => {
  const errors = flattenErrors(event.validate(['name'], false, 'grouped').errors);

  async function onSave() {
    try {
      await event.patchDirty();
      toast(t('Project changes saved.'), {
        type: 'success',
      });

      // If err is instance of Error, it should be of type any.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      let message = e.toString();
      if (e.json) {
        message = e.json.message;
      }
      toast(
        t('Something went wrong while saving the project: {{errorMessage}}', {
          errorMessage: message,
        }),
        {
          type: 'error',
        },
      );
    }
  }

  return (
    <ButtonValid errors={errors} onClick={onSave}>
      {t('Save changes')}
    </ButtonValid>
  );
});

function EventSettingsInfo(): JSX.Element {
  const account = useAccount();
  const { Events, RedirectDomains } = React.useContext(StoresContext);
  const { eventId } = useParams<{ eventId: string }>();
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  const advOptions = (account.get('ttd')?.subadvertisers || []).map((subadv) => ({
    id: subadv.subadv_id,
    name: subadv.name || '',
  }));
  const event = Events.get(eventId);
  const refPageRdrDomain = event.get('ref_page_rdr_domain');
  const mktgPageRdrDomain = event.get('mktg_page_rdr_domain');

  const redirectDomains = RedirectDomains.list();
  const refPageRdr = redirectDomains.models.find(
    (d) => refPageRdrDomain && d.id === refPageRdrDomain.id,
  );
  const mktPageRdr = redirectDomains.models.find(
    (d) => mktgPageRdrDomain && d.id === mktgPageRdrDomain.id,
  );

  function handleChangeTags(newValue: string[]) {
    event.set({ tag_ids: newValue });
  }

  function getOptionLabel(option: IRedirectDomain) {
    return option.domain;
  }

  function handleChangeRefPageRdr(d: IRedirectDomain) {
    event.set({ ref_page_rdr_domain: d });
  }

  function handleChangeMktgPageRdr(d: IRedirectDomain) {
    event.set({ mktg_page_rdr_domain: d });
  }

  function handleSelectAdvertiser(option: { id: string; name: string }) {
    event.set({ subadvertiser: option.id });
  }

  return (
    <Page title={t('Project Information')}>
      <Form
        actions={[<SaveButton event={event} key={'save'} t={t} />]}
        label={t('Event information settings')}
      >
        <ImagePicker attribute={'logo'} model={event} />

        <Input attribute={'name'} label={t('Name')} model={event} type={'text'} />

        <TagsField
          context={'event'}
          label={t('Tags')}
          onChange={handleChangeTags}
          value={event.get('tag_ids', [])}
        />

        <Input
          attribute={['email_preferences', 'alias']}
          helpText={t(
            "Feathr simplifies email subscription management by automatically creating an email subscription for each of your projects. Here, you can specify a custom label to be used for this project's email subscription. Email recipients will see this label when they are managing their subscription preferences. Defaults to the name of the project.",
          )}
          label={t('Custom email subscription label')}
          model={event}
          placeholder={event.name}
          type={'text'}
        />

        <Fieldset>
          <DatePicker attribute={'date_start'} label={t('Start Date')} model={event} />
          <DatePicker attribute={'date_end'} label={t('End Date')} model={event} />
        </Fieldset>

        {account.get('is_agency') && (
          <Fieldset>
            <Select
              helpText={
                <>
                  <p>
                    If this project will primarily house campaigns that advertise on behalf of
                    another organization, please choose that organization here. New campaigns will
                    use this advertiser by default, but it can be changed on a per-campaign basis.
                  </p>
                  <p>
                    Add organizations to this list on the{' '}
                    <Link to={localUrl('settings/account/advertisers')}>Advertisers page</Link> in
                    your account settings.
                  </p>
                </>
              }
              label={'Default Advertiser'}
              name={'default-advertiser'}
              onSelectSingle={handleSelectAdvertiser}
              options={advOptions}
              value={advOptions.find((subadv) => subadv.id === event.get('subadvertiser'))}
            />
          </Fieldset>
        )}

        <Fieldset>
          {account.isFalcon && (
            <Select<IRedirectDomain>
              disabled={event.isPending || redirectDomains.isPending}
              getOptionLabel={getOptionLabel}
              isLoading={event.isPending || redirectDomains.isPending}
              label={t('Invite Page Domain')}
              name={'invite-page-domain'}
              onSelectSingle={handleChangeRefPageRdr}
              options={redirectDomains.models.map((d) => d.toJS())}
              placeholder={redirectDomains.isPending ? t('Loading...') : t('Select a domain...')}
              value={refPageRdr && refPageRdr.toJS()}
            />
          )}
          <Select<IRedirectDomain>
            disabled={event.isPending || redirectDomains.isPending}
            getOptionLabel={getOptionLabel}
            isLoading={event.isPending || redirectDomains.isPending}
            label={t('Landing Page Domain')}
            name={'landing-page-domain'}
            onSelectSingle={handleChangeMktgPageRdr}
            options={redirectDomains.models.map((d) => d.toJS())}
            placeholder={
              event.isPending || redirectDomains.isPending
                ? t('Loading...')
                : t('Select a domain...')
            }
            value={mktPageRdr && mktPageRdr.toJS()}
          />
        </Fieldset>
      </Form>
    </Page>
  );
}

export default observer(EventSettingsInfo);
