import moment from 'moment';

import { TimeFormat } from '@feathr/hooks';
import type { TConstraints } from '@feathr/rachis';

import type { Campaign } from './campaign';
import { EmailBaseCampaign } from './email_base';
import type { IDripCampaign } from './types';
import { CampaignClass, CampaignState } from './types';

export class DripCampaign extends EmailBaseCampaign<IDripCampaign> {
  public override get constraints(): TConstraints<IDripCampaign> {
    /*
     * TODO: reevaluate the constraints for DripCampaign. They share a lot of pinpoint
     * constraints but aren't actually pinpoint campaigns.
     */
    return {
      ...super.constraints,
      date_send_start: {
        datetime: (
          _: string,
          attributes: Partial<IDripCampaign>,
        ): { earliest?: moment.Moment; message?: string } | undefined => {
          if (attributes.send_schedule === 'now') {
            return undefined;
          }
          if (
            attributes.state &&
            [CampaignState.Draft, CampaignState.Stopped].includes(attributes.state)
          ) {
            return {
              earliest: moment.utc().add(15, 'minutes'),
              message: `^Start time must be at least 15 minutes in the future`,
            };
          }
          return undefined;
        },
        presence: (
          _: string,
          attributes: Partial<IDripCampaign>,
        ): { allowEmpty: boolean; message: string } => {
          return {
            allowEmpty: false,
            message:
              attributes.send_schedule === 'now'
                ? '^Start date cannot be empty'
                : '^Start date/time cannot be empty',
          };
        },
      },
      date_send_end: {
        /*
         * Instead of letting the date picker itself to not allow the user to select a date before the start date,
         * we can enforce it here.
         */
        datetime: (
          value: string,
          attributes: Partial<IDripCampaign>,
        ): { earliest?: string; message: string } | undefined => {
          if (attributes.date_send_start) {
            const dateStart = moment.utc(attributes.date_send_start, moment.ISO_8601);
            return {
              earliest: dateStart
                .add(23, 'hours')
                .add(59, 'minutes')
                .format(TimeFormat.isoDateTime),
              message:
                attributes.send_schedule === 'now'
                  ? '^End date must be at least 24 hours after start time'
                  : '^End date/time must be at least 24 hours after start time',
            };
          }
          return undefined;
        },
        presence: (
          _: string,
          attributes: Partial<IDripCampaign>,
        ): { allowEmpty: boolean; message: string } => {
          return {
            allowEmpty: false,
            message:
              attributes.send_schedule === 'now'
                ? '^End date cannot be empty'
                : '^End date/time cannot be empty',
          };
        },
      },
      date_end: undefined,
      'consent.has_consent': {
        presence: {
          allowEmpty: false,
          message: '^You must provide consent to publish this campaign',
        },
        exclusion: {
          within: [false],
          message: '^You must provide consent to publish this campaign',
        },
      },
    };
  }

  public override getDefaults(): Partial<IDripCampaign> {
    return {
      ...super.getDefaults(),
      _cls: CampaignClass.Drip,
    };
  }

  public get linkedCampaignIds(): string[] {
    return this.get('linked_campaigns').map(({ campaign }) => campaign);
  }
}

// Function that allows type narrowing for Drip Campaigns.
export function isDripCampaign(campaign: Campaign): campaign is DripCampaign {
  return campaign.get('_cls') === CampaignClass.Drip;
}
