import { moment, TimeFormat } from '@feathr/hooks';
import type { TConstraints } from '@feathr/rachis';

import { Campaign } from './campaign';
import type { TCampaignAttributes, TTrackedLinkCampaign } from './types';

export class TrackedLinkCampaign extends Campaign<TTrackedLinkCampaign> {
  public override get constraints(): TConstraints<TCampaignAttributes> {
    return {
      ...super.constraints,
      // Tracked link campaigns do not depend on any temporal data.
      date_start: undefined,
      date_end: undefined,
      tracked_links: {
        length: {
          minimum: 1,
          message: '^Please add a tracked link.',
        },
        array: {
          original_url: {
            presence: {
              allowEmpty: false,
              message: '^A valid URL must be provided.',
            },
            url: {
              message: '^A valid URL must be provided.',
            },
          },
          utm_source: {
            presence: {
              allowEmpty: false,
              message: '^Please add UTM source.',
            },
          },
          utm_medium: {
            presence: {
              allowEmpty: false,
              message: '^Please add UTM medium.',
            },
          },
        },
      },
    };
  }

  public override getDefaults(): Partial<TTrackedLinkCampaign> {
    return {
      ...super.getDefaults(),
      date_end: moment.utc().add(1, 'y').format(TimeFormat.isoDate),
    };
  }

  // Override date logic for tracked link campaigns
  public override get isPastEndDate(): boolean {
    return false;
  }

  // Override date logic for tracked link campaigns
  public override get isPastStartDate(): boolean {
    return false;
  }
}
