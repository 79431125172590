import type { TConstraints } from '@feathr/rachis';

import { SmartPinpointEmailBaseCampaign } from './smart_pinpoint_email_base';
import type { IAutoPinpointEmailBaseCampaign } from './types';

export abstract class AutoPinpointEmailBaseCampaign<
  T extends IAutoPinpointEmailBaseCampaign = IAutoPinpointEmailBaseCampaign,
> extends SmartPinpointEmailBaseCampaign<T> {
  public override get constraints(): TConstraints<T> {
    return {
      ...super.constraints,
      repeat: {
        presence: {
          allowEmpty: false,
          message:
            "^Choose whether you'd like your email to be sent only once or each time the action is performed",
        },
      },
      segments: {
        presence: {
          allowEmpty: true,
        },
      },
      cooldown_value: {
        presenceUnless: {
          allowEmpty: false,
          unless: ({ repeat }) => repeat === false,
        },
      },
      cooldown_unit: {
        presenceUnless: {
          allowEmpty: false,
          unless: ({ repeat }) => repeat === false,
        },
      },
      // Only validate the presence of time to send if a date trigger type has been selected
      time_to_send: {
        presenceUnless: {
          allowEmpty: false,
          message: "^Time of day can't be blank",
          unless: ({ subtype }) => subtype !== 'time',
        },
      },
      template_id: {
        presence: {
          allowEmpty: false,
          message: '^You must select a template',
        },
      },
    };
  }

  public override getDefaults(): Partial<T> {
    return {
      ...super.getDefaults(),
      mode: 'match_all',
      actions: [
        /**
         * Defining the default actions like this allows us
         * to render the first PredicateInput in an unselected state.
         * This is necessary to show the user that both activity and
         * attribute predicates are available for selection.
         */
        {
          attr_against: undefined,
          kind: 'update',
        },
      ],
      send_schedule: 'later',
      delay_value: 30,
      delay_unit: 'minutes',
      subtype: 'activity',
    };
  }
}
