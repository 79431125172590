import { useDisclosure } from '@mantine/hooks';

export interface IUseCampaignModalsReturn {
  modalHandlers: {
    openArchiveModal: () => void;
    closeArchiveModal: () => void;
    openDuplicateModal: () => void;
    closeDuplicateModal: () => void;
    openDeleteModal: () => void;
    closeDeleteModal: () => void;
    openRemoveFromFlightModal: () => void;
    closeRemoveFromFlightModal: () => void;
    openEnableModal: () => void;
    closeEnableModal: () => void;
    openStopModal: () => void;
    closeStopModal: () => void;
  };
  modalStates: {
    isArchiveModalOpen: boolean;
    isDuplicateModalOpen: boolean;
    isDeleteModalOpen: boolean;
    isRemoveFromFlightModalOpen: boolean;
    isEnableModalOpen: boolean;
    isStopModalOpen: boolean;
  };
}

/**
 * The useCampaignModals hook allows ups to keep the state and handlers associated with triggering
 * and displaying modals for specific campaign actions. This includes archiving, cloning, deleting,
 * and removing from a flight.
 *
 * This allows us to standardize the methods repeated across the app, since these behaviors will
 * soon be available from multiple locations: campaign table & campaign edit page
 */
function useCampaignModals(): IUseCampaignModalsReturn {
  // Modal state and handlers
  const [isArchiveModalOpen, { open: openArchiveModal, close: closeArchiveModal }] =
    useDisclosure(false);
  const [isDuplicateModalOpen, { open: openDuplicateModal, close: closeDuplicateModal }] =
    useDisclosure(false);
  const [isDeleteModalOpen, { open: openDeleteModal, close: closeDeleteModal }] =
    useDisclosure(false);
  const [
    isRemoveFromFlightModalOpen,
    { open: openRemoveFromFlightModal, close: closeRemoveFromFlightModal },
  ] = useDisclosure(false);
  const [isEnableModalOpen, { open: openEnableModal, close: closeEnableModal }] =
    useDisclosure(false);
  const [isStopModalOpen, { open: openStopModal, close: closeStopModal }] = useDisclosure(false);

  return {
    modalHandlers: {
      openArchiveModal,
      closeArchiveModal,
      openDuplicateModal,
      closeDuplicateModal,
      openDeleteModal,
      closeDeleteModal,
      openRemoveFromFlightModal,
      closeRemoveFromFlightModal,
      openEnableModal,
      closeEnableModal,
      openStopModal,
      closeStopModal,
    },
    modalStates: {
      isArchiveModalOpen,
      isDuplicateModalOpen,
      isDeleteModalOpen,
      isRemoveFromFlightModalOpen,
      isEnableModalOpen,
      isStopModalOpen,
    },
  };
}

export default useCampaignModals;
