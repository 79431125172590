import type { Event } from '@sentry/react';
import { captureEvent } from '@sentry/react';
import { isEqual, isObject } from 'lodash';
import camelCase from 'lodash.camelcase';
import { nanoid } from 'nanoid';
import { stringify } from 'qs';

interface INestedErrors {
  [key: string | number]: string | string[] | INestedErrors | INestedErrors[] | undefined;
}

export function flattenError(err?: string | string[] | INestedErrors | INestedErrors[]): string[] {
  if (!err) {
    return [];
  }

  function flatten(o: string | string[] | INestedErrors | INestedErrors[]): string[] {
    return Object.values(o)
      .flatMap((value) =>
        typeof value === 'object' && value !== null ? flatten(value as INestedErrors) : value,
      )
      .filter((v): v is string => !!v);
  }

  // Deduplicate error messages before returning.
  return [...new Set(flatten(err))];
}

export function flattenErrors(obj: INestedErrors): string[] {
  return flattenError(obj);
}

/**
 * @param order If omitted, object will be sorted alphabetically.
 */
export function sortErrors(obj: INestedErrors, order?: Array<keyof INestedErrors>): INestedErrors {
  return (
    Object.keys(obj)
      // Sorts alphabetically if no order is provided.
      .sort((a, b) => (order?.length ? order.indexOf(a) - order.indexOf(b) : a.localeCompare(b)))
      .reduce((acc, key) => {
        acc[key] = obj[key];

        return acc;
      }, {})
  );
}

export function concatPath(...parts: Array<string | undefined>): string {
  return parts.reduce((path: string, part) => {
    if (!part) {
      return path;
    }
    return path.concat(path.slice(-1) === '/' ? '' : '/', part[0] === '/' ? part.slice(1) : part);
  }, '');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getQuery<T extends Record<string, any> = Record<string, any>>(
  url: string = window.location.href,
): T {
  return fromQueryString(new URL(url, window.location.href).search);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toQueryString(args: Record<string, any>): string {
  // Deep array cannot be encoded; returns [object Object].

  // TODO: Should we throw an error when deep array is encountered?
  return stringify(args, { arrayFormat: 'comma', sort: (a, b) => a.localeCompare(b) });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fromQueryString<T extends Record<string, any> = Record<string, any>>(
  queryString: string,
): T {
  const params: Record<string, string> = Object.fromEntries(new URLSearchParams(queryString));
  return Object.entries(params).reduce((a, [i, v]) => {
    // We don't know if the value is a JSON string or not, so...
    try {
      a[i] = JSON.parse(v);
    } catch {
      a[i] = v;
    }
    return a;
  }, {}) as T;
}

interface IPath {
  hash?: string;
  pathname?: string;
  search?: string;
}

export function parseUri(path: string): IPath {
  const parsed = path.match(/([^\?\#]+)(?:\?([^\#]+))?(?:\#(.+))?/);
  const [, pathname, search, hash] = parsed || [undefined, undefined, undefined, undefined];
  return { pathname, search, hash };
}

export function joinUri({ pathname, search, hash }: IPath): string {
  return `${pathname}${search ? `?${search}` : ''}${hash ? `#${hash}` : ''}`;
}

// @see https://github.com/mrded/is-url-external
export function isExternalUrl(url): boolean {
  const host = window.location.hostname;

  const linkHost = (function (url: string): string {
    if (/^https?:\/\//.test(url)) {
      /*
       * Absolute URL.
       * The easy way to parse an URL, is to create <a> element.
       * @see: https://gist.github.com/jlong/2428561
       */
      const parser = document.createElement('a');
      parser.href = url;

      return parser.hostname;
    } else {
      // Relative URL.
      return window.location.hostname;
    }
  })(url);

  return host !== linkHost;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function enumKeys<T extends {}>(e: T): Array<keyof T> {
  return Object.keys(e)
    .filter((key) => typeof key === 'string')
    .reduce(
      (acc, val) => {
        acc.push(val as keyof T);
        return acc;
      },
      [] as Array<keyof T>,
    );
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function enumToObject<T extends {}>(e: T): Record<keyof T, string> {
  return Object.keys(e)
    .filter((key) => typeof key === 'string')
    .reduce(
      (acc, val) => {
        acc[val] = e[val];
        return acc;
      },
      {} as Record<keyof T, string>,
    );
}

/**
 * Helper function returns a promise that resolves after all other promise mocks,
 * even if they are chained like Promise.resolve().then(...)
 * Technically: this is designed to resolve on the next macrotask
 *
 * @see https://stackoverflow.com/a/43855794/10791482
 */
export function tick(ms = 0): Promise<unknown> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

/**
 * Escape a string so it can be used as a regular expression
 * see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
 */
export function escapeRegExp(string: string): string {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeEmpty(obj: Record<string, any>): Record<string, any> {
  return Object.keys(obj).reduce(
    (newFilters, key) => {
      if (Array.isArray(obj[key]) ? obj[key].length : obj[key] !== undefined) {
        newFilters[key] = obj[key];
      }
      return newFilters;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    },
    {} as Record<string, any>,
  );
}

/**
 * Returns a string of random lowercase alphabetical characters to be used as a
 * starting point for redirect short codes.
 */
export function shortCodeToken(length: number): string {
  return nanoid(length);
}

/**
 * Returns the value of a css custom property (css variable) in :root.
 */
export function cssVar(name: string): string {
  return getComputedStyle(document.documentElement).getPropertyValue(name).trim();
}

/**
 * Log error in Sentry
 */
export function logError(error: Event): void {
  captureEvent(error);
}

export function logUserEvents(events: Record<string, Record<string, unknown> | null>): void {
  for (const [eventName, properties] of Object.entries(events)) {
    window.Appcues?.track(eventName, properties ?? {});
  }
}

export type { SeverityLevel } from '@sentry/react';

export function stringifyBreadcrumbs(urlSegments: Array<[string, string]>): string {
  return urlSegments.reduce((acc, current, index) => {
    // Add a separator if there's already an item inside acc
    const accWithSeparator = `${acc}${acc && '.'}`;
    const containsNumbers = /\d/.test(current[1]);
    // If the param has contains numbers, it is an id so do not add it to the accumulator
    if (containsNumbers) {
      /*
       * If the last even param is an id - use the previous item's name, removing the trailing s
       * For example: flights -> flight
       */
      if (index === urlSegments.length - 1) {
        const prev = urlSegments[urlSegments.length - 2][1];
        if (prev === 'people') {
          return `${accWithSeparator}person`;
        }
        return `${accWithSeparator}${prev.slice(0, -1)}`;
      }
      return acc;
    }
    return `${accWithSeparator}${current[1]}`;
  }, '');
}

export function hasDuplicates(array: Array<number | string>): boolean {
  return new Set(array).size !== array.length;
}

export function getLength(input: string | number | undefined): number {
  // Ensure the input is a string for .length to work
  const str = input?.toString();

  // Return the length of the string
  return str ? str.length : 0;
}

/**
 * Returns all combinations of two arrays
 */
export function generateArrayPermutations(array1: string[], array2: string[]): string[][] {
  const permutations: string[][] = [];

  for (const iterator1 of array1) {
    for (const iterator2 of array2) {
      permutations.push([iterator1, iterator2]);
    }
  }

  return permutations;
}

export function shuffleArray(array: any[]): any[] {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]]; // Swap elements
  }
  return newArray;
}

export function pickRandomItems(array: string[], numItems: number): string[] {
  const filteredArray = array.filter((item) => item !== '');
  const shuffledArray = shuffleArray(filteredArray);

  return shuffledArray.slice(0, numItems);
}

export function simpleHash(inputString, iteractor = 0): string {
  let hashValue = BigInt(iteractor);
  const hashSpace = BigInt(2 ** 96);
  for (let i = 0; i < inputString.length; i++) {
    const charCode = inputString.charCodeAt(i);
    hashValue = (hashValue * BigInt(31) + BigInt(charCode)) % hashSpace;
  }
  const hashString = hashValue.toString(16).padStart(24, '0');
  return hashString;
}

function hex(value: number): string {
  return Math.floor(value).toString(16);
}

/** Generate mongo-style ids */
export function objectId(): string {
  return (
    hex(Date.now() / 1000) +
    ' '.repeat(16).replace(/./g, () => {
      // Use a secure random value rather than insecure math.random
      const typedArray = new Uint8Array(1);
      const randomValue = crypto.getRandomValues(typedArray)[0];
      const randomFloat = randomValue / Math.pow(2, 8);

      return hex(randomFloat * 16);
    })
  );
}

export function formatPhoneNumber(phoneNumber: string, countryCode?: string): string {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = /^(\d{3})(\d{3})(\d{4})$/.exec(cleaned);

  const countryCodeMap = {
    US: '+1',
    CA: '+1',
    GB: '+44',
  };

  const countryNumber = countryCode ? `${countryCodeMap[countryCode] ?? '+1'} ` : '';

  if (match) {
    return `${countryNumber}(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return phoneNumber;
}

export function formatInitials(name: string): string {
  if (!name) {
    return '';
  }

  const words = name.trim().split(/\s+/); // Split by one or more whitespace characters
  const firstName = words[0] || '';
  const lastName = words.length > 1 ? words[words.length - 1] : '';

  return (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
}

export function removeElementAtIndex<T>(array: T[], index: number): T[] {
  if (index < 0 || index >= array.length) {
    throw new Error('Index out of bounds');
  }
  return array.slice(0, index).concat(array.slice(index + 1));
}

/**
 * Function to generate unique keys for non-unique array objects.
 * Preserves existing key if set.
 */
export function generateKeys<T extends object>(
  array: Array<T & { key?: string }>,
): Array<T & { key: string }> {
  return array.map((obj) => ({
    ...obj,
    key: obj.key || objectId(),
  }));
}

export function getNestedValue(object: any, attributes: string[]): any {
  // Make sure model is not pending.
  if (!object) {
    return object;
  }

  if (attributes.length > 1) {
    const currentAttribute = attributes.shift()!;
    return getNestedValue(object[currentAttribute], attributes);
  }
  if (attributes.length === 1) {
    return object[attributes[0]];
  }
  // This shouldn't ever happen.
  return object;
}

export function setNestedValue(object: any, attributes: string[], value: any): any {
  // Make sure model is not pending.
  if (!object) {
    return object;
  }

  if (attributes.length > 1) {
    const currentAttribute = attributes.shift()!;
    return { [currentAttribute]: setNestedValue(object[currentAttribute], attributes, value) };
  }
  if (attributes.length === 1) {
    object[attributes[0]] = value;
    return object;
  }
  // This shouldn't ever happen.
  return value;
}

// Save object to local storage within a namespace object
export function saveToLocalStorage<T>(namespace: string, key: string, data: T): void {
  try {
    const namespaceData = JSON.parse(localStorage.getItem(namespace) ?? '{}');
    namespaceData[key] = data;
    localStorage.setItem(namespace, JSON.stringify(namespaceData));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error saving to localStorage:', error);
  }
}

// Retrieve object from local storage within a namespace object
export function getFromLocalStorage<T>(namespace: string, key: string): T | undefined {
  try {
    const namespaceData = JSON.parse(localStorage.getItem(namespace) ?? '{}');
    return key in namespaceData ? (namespaceData[key] as T) : undefined;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error retrieving from localStorage:', error);
    return undefined;
  }
}

// Converts hyphenated string to capitalized parts (e.g., "foo-bar" -> "FooBar")
type TSplitHyphen<S extends string> = S extends `${infer F}-${infer R}`
  ? `${Capitalize<F>}${TSplitHyphen<R>}`
  : Capitalize<S>;

// Concatenates string array into camelCase (e.g., ["foo", "bar-baz"] -> "fooBarBaz")
type TConcatStrings<T extends string[]> = T extends [
  infer F extends string,
  ...infer R extends string[],
]
  ? `${F}${R extends [] ? '' : TSplitHyphen<TConcatStrings<R>>}`
  : '';

// Concatenate args into a single string and camelCase it with type checking
export function concatClassName<A extends string[]>(...args: A): TConcatStrings<A> {
  return camelCase(args.join(' ')) as TConcatStrings<A>;
}

export const getNestedObjectDiff = (
  obj1: Record<string, any>,
  obj2: Record<string, any>,
): Record<string, any> => {
  const diff: Record<string, any> = {};

  Object.keys(obj1).forEach((key) => {
    if (!isEqual(obj1[key], obj2[key])) {
      if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
        if (!isEqual(obj1[key], obj2[key])) {
          diff[key] = { initial: obj1[key], current: obj2[key] };
        }
      } else if (isObject(obj1[key]) && isObject(obj2[key])) {
        const nestedDiff = getNestedObjectDiff(obj1[key], obj2[key]);
        if (Object.keys(nestedDiff).length > 0) {
          diff[key] = nestedDiff;
        }
      } else {
        diff[key] = { initial: obj1[key], current: obj2[key] };
      }
    }
  });

  Object.keys(obj2).forEach((key) => {
    if (!(key in obj1)) {
      diff[key] = { initial: undefined, current: obj2[key] };
    }
  });

  return diff;
};
