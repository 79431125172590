import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { isWretchError } from '@feathr/rachis';
import { Collection, Model, wretch } from '@feathr/rachis';

export interface ILineItem {
  price: number;
  quantity: number;
  description: string;
  itemid: string;
  period?: { start: string; end: string };
  campaign_id: string;
}

export enum BillClass {
  Bill = 'Bill',
  PrepaidMediaBill = 'PrepaidMediaBill',
}

export interface IBill extends IBaseAttributes {
  _cls: BillClass;
  stripe?: {
    id: string;
    billing: 'send_invoice' | 'charge_automatically';
    hosted_invoice_url: string;
    invoice_pdf: string;
    metadata: Record<string, unknown>;
    status: 'draft' | 'paid' | 'open' | 'uncollectable' | 'void';
    credit_note?: string;
  };
  intacct: {
    key: string;
    recordno: string;
    documentnumber: string;
    prrecord: Record<string, unknown>;
    transaction_type: string;
  };
  account_id: string;
  line_items: ILineItem[];
  amount_due: number;
  period: {
    start: string;
    end: string;
  };
  due_date: string;
  description: string;
}

export class Bill<T extends IBill = IBill> extends Model<T> {
  public readonly className: 'Bill' | 'PrepaidMediaBill' = 'Bill';

  public get constraints(): TConstraints<IBill> {
    return {};
  }
}

export interface IPrepaidMediaBill extends IBill {
  event_id: string;
}

export class PrepaidMediaBill extends Bill<IPrepaidMediaBill> {
  public override readonly className = 'PrepaidMediaBill';

  public override get constraints(): TConstraints<IPrepaidMediaBill> {
    return {};
  }
}

export class Bills extends Collection<Bill | PrepaidMediaBill> {
  public getClassName(): string {
    return 'bills';
  }

  public override url(): string {
    return `${this.getHostname()}billing/bills/`;
  }

  public getModel(attributes: Partial<IBill>): Bill<IBill> {
    if (attributes._cls === BillClass.PrepaidMediaBill) {
      return new PrepaidMediaBill(attributes);
    }
    return new Bill(attributes);
  }

  public async prepay(
    amount: number,
    event_id: string,
    created_by?: string,
    po_number?: string,
  ): Promise<PrepaidMediaBill> {
    const body = {
      amount,
      event_id,
      po_number,
      created_by,
    };
    const response = await wretch<IPrepaidMediaBill>(`${BLACKBOX_URL}events/${event_id}/prepay`, {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify(body),
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return this.processJSONResponse(response) as PrepaidMediaBill;
  }
}
