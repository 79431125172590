import classNames from 'classnames';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { List, ListItem } from '@feathr/components';

import FormFields from '../FormFields';

import * as styles from './ConfigurationPanel.css';

function ConfigurationPanel(): JSX.Element {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<'form-fields' | 'design'>('form-fields');

  function handleClickFormFields(): void {
    setActiveTab('form-fields');
  }

  function handleClickDesign(): void {
    setActiveTab('design');
  }

  return (
    <div className={styles.root}>
      <List className={styles.tabs}>
        <ListItem
          className={classNames({ [styles.active]: activeTab === 'form-fields' })}
          onClick={handleClickFormFields}
        >
          {t('Form Fields')}
        </ListItem>
        <ListItem
          className={classNames({ [styles.active]: activeTab === 'design' })}
          onClick={handleClickDesign}
        >
          {t('Design')}
        </ListItem>
      </List>
      <div className={styles.content}>
        {activeTab === 'form-fields' && <FormFields />}
        {activeTab === 'design' && <div>Design</div>}
      </div>
    </div>
  );
}

export default ConfigurationPanel;
