import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FieldDataType } from '@feathr/blackbox';
import { Icon, Input } from '@feathr/components';

import type { IField } from '../Builder';
import Field from './Field';

import * as styles from './FormFields.css';

function FormFields(): JSX.Element {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');

  function handleSearch(value?: string): void {
    setSearch(value || '');
  }

  // TODO: Replace with actual fields
  const fields = (function (): Array<Pick<IField, 'type' | 'label'>> {
    const fields = [
      { type: FieldDataType.str, label: 'Name' },
      { type: FieldDataType.int, label: 'Level' },
      { type: FieldDataType.bool, label: 'Mortal' },
      { type: FieldDataType.date, label: 'Birthday' },
      { type: FieldDataType.list, label: 'Inventory' },
      { type: FieldDataType.str, label: 'Class' },
      { type: FieldDataType.int, label: 'Max HP' },
      { type: FieldDataType.int, label: 'Current HP' },
      { type: FieldDataType.bool, label: 'Alive' },
      { type: FieldDataType.date, label: 'Last Login' },
      { type: FieldDataType.list, label: 'Companions' },
    ];

    if (search.length > 0) {
      return fields.filter((field) => field.label.toLowerCase().includes(search.toLowerCase()));
    }

    return fields;
  })();

  return (
    <>
      <Input
        isClearable={true}
        onChange={handleSearch}
        placeholder={t('Search by name...')}
        prefix={<Icon icon={faSearch} />}
        type={'text'}
        value={search}
        wrapperClassName={styles.input}
      />

      <div className={styles.container}>
        {fields.map((field) => (
          <Field field={field} key={field.type} />
        ))}
      </div>
    </>
  );
}

export default FormFields;
