import type { Content, EditorEvents } from '@tiptap/react';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, CardV2 as Card, RichTextEditorV2 as RichTextEditor } from '@feathr/components';

function Settings(): JSX.Element {
  const { t } = useTranslation();
  const [content, setContent] = useState<Content>('<u>Thank you for submitting the form</u>');

  function handleChange(props: EditorEvents['update']): void {
    setContent(props.editor.getHTML());
  }

  return (
    <Card width={'wide'}>
      <Card.Header title={t('Thank You Message')} />
      <Card.Content>
        <RichTextEditor
          content={content}
          excludeControls={{ heading: ['h5', 'h6'] }}
          onChange={handleChange}
        />
      </Card.Content>
      <Card.Actions>
        <Button>{t('Apply')}</Button>
      </Card.Actions>
    </Card>
  );
}

export default Settings;
