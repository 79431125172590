import { faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';
import type { JSX } from 'react';
import React from 'react';

import { FieldDataType } from '@feathr/blackbox';
import { DatePicker, Icon } from '@feathr/components';
import { Input } from '@feathr/components';

import type { IField } from '../Builder.utils';

import * as styles from './DraggableFormElement.css';

interface IProps {
  field: IField;
}

function DraggableFormElement({ field: { label, value, type } }: IProps): JSX.Element | undefined {
  const element = (function (): JSX.Element | undefined {
    const shared = { label, wrapperClassName: styles.input };
    switch (type) {
      case FieldDataType.str:
        return <Input {...shared} type={'text'} value={value as string} />;

      case FieldDataType.int:
        return <Input {...shared} type={'number'} value={value as number} />;

      case FieldDataType.date:
        return <DatePicker {...shared} value={value as string} />;
    }

    return undefined;
  })();

  return (
    <div className={styles.root}>
      <Icon className={styles.icon} icon={faGripDotsVertical} />
      {element}
    </div>
  );
}

export default DraggableFormElement;
