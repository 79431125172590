import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@feathr/components';
import { getIconForAction } from '@feathr/hooks';

interface IProps {
  onClick: () => void;
}

function CustomizeColumnsButton({ onClick }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  return (
    <Button onClick={onClick} prefix={getIconForAction('customize')}>
      {t('Customize columns')}
    </Button>
  );
}

export default CustomizeColumnsButton;
