import {
  faDrawCircle,
  faFileLines,
  faFolders,
  faGear,
  faHome,
  faQuestionCircle,
  faRectanglePro,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import {
  AvatarV2 as Avatar,
  Icon,
  ListItem,
  Nav,
  NavItem,
  NavItemHeader,
  NavMenu,
  NavMenuProvider,
  Popover,
} from '@feathr/components';
import AccountSwitcher from '@feathr/extender/components/AccountSwitcher/AccountSwitcher';
import UnreadNotifications from '@feathr/extender/components/UnreadNotifications';
import { useAccount, useFlags, useLocalUrl, useUser } from '@feathr/extender/state';

import DataNav from './DataNav';
import EventNav from './EventNav';
import HelpNav from './HelpNav';
import HomeNav from './HomeNav';
import SettingsNav from './SettingsNav';
import UserNav from './UserNav';

import styles from './PrimaryNav.css';

interface IProps {
  className?: string;
  secondaryNav?: ReactNode;
}

function PrimaryNav({ className }: Readonly<IProps>): JSX.Element | null {
  const localUrl = useLocalUrl();
  const match = useRouteMatch<{ accountId: string; primary: string }>({
    path: '/:accountId/:primary',
  });
  const account = useAccount();
  const user = useUser();
  const { t } = useTranslation();
  const { showForms } = useFlags();

  const picture = (!!user && user.get('picture')) || '';
  const activePackage = account?.activePackage;

  const isPro = account.isPro;

  const userName = user.get('name') ?? user.get('email') ?? user.name;

  if (!match) {
    return null;
  }

  return (
    <NavMenuProvider>
      <Nav
        className={classNames(styles.root, className)}
        label={'Primary navigation'}
        type={'vertical'}
      >
        <ListItem className={styles.logo}>
          <AccountSwitcher />
        </ListItem>
        {(activePackage || user?.isSudoer) && (
          <>
            <NavMenu
              icon={faHome}
              // Home menu needs to expand only for sumbenu items and not just look at path
              isActive={['accounts', 'dashboard', 'marketing'].includes(match.params.primary)}
              menu={<HomeNav />}
              rel={'home'}
              to={localUrl('')}
            >
              {t('Home')}
            </NavMenu>

            <NavMenu icon={faFolders} menu={<EventNav />} to={localUrl('/projects')}>
              {t('Projects')}
            </NavMenu>

            <NavMenu icon={faDrawCircle} menu={<DataNav />} to={localUrl('/data')}>
              {t('Community')}
            </NavMenu>
          </>
        )}

        <NavMenu icon={faGear} menu={<SettingsNav />} to={localUrl('/settings')}>
          {t('Account Settings')}
        </NavMenu>

        {showForms && (
          <NavItem icon={<Icon icon={faFileLines} />} to={localUrl('/temp-form')}>
            {t('Forms (WIP)')}
          </NavItem>
        )}

        <NavItem
          className={styles.help}
          footer={
            <Popover align={'end'} className={styles.popper} position={'right'} toggle={'onClick'}>
              <NavItemHeader prefix={<FontAwesomeIcon icon={faQuestionCircle} />}>
                {t('Help')}
              </NavItemHeader>
              <HelpNav />
            </Popover>
          }
        />

        {isPro && (
          <NavItem
            icon={<FontAwesomeIcon icon={faRectanglePro} />}
            to={localUrl('/settings/pro-resources')}
          >
            {t('Resources')}
          </NavItem>
        )}

        <NavItem className={styles.notifications} footer={<UnreadNotifications />} />

        <NavItem
          className={styles.avatar}
          footer={
            <Popover align={'end'} className={styles.popper} position={'right'} toggle={'onClick'}>
              <NavItemHeader prefix={<Avatar image={picture} name={userName} size={'xs'} />}>
                {/* If name is not set, try to use email, else use "Unnamed User */}
                {userName}
              </NavItemHeader>
              <UserNav />
            </Popover>
          }
        />
      </Nav>
    </NavMenuProvider>
  );
}

export default observer(PrimaryNav);
