import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IDripCampaignStepSpec } from '@feathr/blackbox';
import { ButtonValid, CardV2 as Card, Label } from '@feathr/components';
import { flattenErrors } from '@feathr/hooks';

import MergeDataInputs from '../../../EmailDetails/MergeDataInputs';
import { validateAutomation } from '../../DripCampaignEdit.utils';
import EmailInputs from '../EmailInputs';
import useEphemeralCampaign from '../useEphemeralCampaign';
import useSyncStepsWithStepSpecs from '../useSyncStepsWithStepSpecs';
import StepTargeting from './StepTargeting';

interface IEditAutomationProps {
  dripCampaignId: string;
  step: IDripCampaignStepSpec;
  stepIndex: number;
  onApply: (updatedStep: IDripCampaignStepSpec) => Promise<void>;
  onSync: (updatedStep: IDripCampaignStepSpec) => Promise<void>;
  onCancel: () => void;
  unverifiedFromAddresses: string[];
}

function EditAutomation({
  dripCampaignId,
  step,
  stepIndex,
  onApply,
  onSync,
  onCancel,
  unverifiedFromAddresses: unverified,
}: Readonly<IEditAutomationProps>): JSX.Element {
  const { t } = useTranslation();
  const { campaign, handleApply, handleSync } = useEphemeralCampaign({
    dripCampaignId,
    onApply,
    onCancel,
    onSync,
    step,
  });
  const validationErrors = validateAutomation({
    campaign,
    includeName: false,
    isVerifiedSender:
      unverified && step.from_address ? !unverified.includes(step.from_address) : undefined,
  });
  useSyncStepsWithStepSpecs({ ephemeralCampaign: campaign, handleSync });

  return (
    <Card theme={'disabled'} width={'full'}>
      <Card.Content>
        <Label weight={'semibold'}>{t('Automation')}</Label>
        <StepTargeting campaign={campaign} validationErrors={validationErrors} />
      </Card.Content>
      <Card.Content>
        <Label weight={'semibold'}>{t('Email')}</Label>
        <EmailInputs campaign={campaign} validationErrors={validationErrors} />
      </Card.Content>
      <Card.Content>
        <Label margin={'condensed'} weight={'semibold'}>
          {t('Default Merge Data')}
        </Label>
        <p>
          {t(
            'These values will be used to replace merge tags when the recipient does not have data for the corresponding field.',
          )}
        </p>
        <MergeDataInputs campaign={campaign} disabled={false} />
      </Card.Content>
      <Card.Actions>
        {/* TODO: Bring back when we can be sure cancel works properly with sync */}
        {/* <Button name={`step-${stepIndex + 1}-cancel`} onClick={handleCancel}>
          {t('Cancel')}
        </Button> */}
        <ButtonValid
          errors={flattenErrors(validationErrors)}
          name={`step-${stepIndex + 1}-apply`}
          onClick={handleApply}
          type={'primary'}
        >
          {t('Apply')}
        </ButtonValid>
      </Card.Actions>
    </Card>
  );
}

export default observer(EditAutomation);
