import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import type { PinpointPartnerMessage } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import {
  ArchiveModalV1,
  ConfirmModalV1,
  ContextMenu,
  EAlertV2Type,
  toast,
} from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';
import { getIconForAction, useToggle } from '@feathr/hooks';

import PartnerMessageSummary from '../EventPartnersMessagePage/PartnerMessageSummary';

interface IEventPartnersMessagesOptions {
  message: PinpointPartnerMessage;
}

function EventPartnersMessagesOptions({ message }: IEventPartnersMessagesOptions): JSX.Element {
  const history = useHistory();
  const localUrl = useLocalUrl();
  const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);
  const [isDuplicateModalOpen, toggleDuplicateModalOpen] = useToggle(false);
  const [isDeleteModalOpen, toggleDeleteModalOpen] = useToggle(false);
  const { t } = useTranslation();

  const state = message.get('state');

  async function duplicate(): Promise<void> {
    const duplicatedMessage: PinpointPartnerMessage = await message.clone({});
    history.push(localUrl(duplicatedMessage.getItemUrl()));
  }

  async function archive(): Promise<void> {
    /*
     * Close immediately to prevent "Can't perform a React state update on an
     * unmounted component." warning.
     */
    toggleArchiveModalOpen();
    try {
      await message.archive();
      toast(t('Message {{name}} has been archived.', { name: message.name }));
    } catch (error) {
      toast(t('There was a problem archiving the message:\n{{error}}', { error }));
    }
  }

  async function destroy(): Promise<void> {
    /*
     * Close immediately to prevent "Can't perform a React state update on an
     * unmounted component." warning.
     */
    toggleDeleteModalOpen();
    message.set({
      is_archived: true,
      state: CampaignState.Archived,
    });
    try {
      await message.delete();
      toast(t('Message has been deleted.'));
    } catch (error) {
      toast(t('There was a problem deleting the message:\n{{error}}', { error }));
    }
  }

  return (
    <>
      <ContextMenu buttonType={'icon'} position={'left-start'}>
        <ContextMenu.Item onClick={toggleDuplicateModalOpen} prefix={getIconForAction('duplicate')}>
          {t('Duplicate')}
        </ContextMenu.Item>
        {state === CampaignState.Draft ? (
          <ContextMenu.Item
            onClick={toggleDeleteModalOpen}
            prefix={getIconForAction('delete')}
            theme={'danger'}
          >
            {t('Delete')}
          </ContextMenu.Item>
        ) : (
          <ContextMenu.Item
            onClick={toggleArchiveModalOpen}
            prefix={getIconForAction('archive')}
            theme={'danger'}
          >
            {t('Archive')}
          </ContextMenu.Item>
        )}
      </ContextMenu>
      {isDuplicateModalOpen && (
        <ConfirmModalV1
          cancelButtonText={t('Cancel')}
          confirmButtonText={t('Duplicate')}
          message={t('Are you sure you want to duplicate this message?')}
          onClose={toggleDuplicateModalOpen}
          onConfirm={duplicate}
          t={t}
          title={t('Duplicate Message')}
          type={EAlertV2Type.info}
        >
          <PartnerMessageSummary message={message} />
        </ConfirmModalV1>
      )}
      {isArchiveModalOpen && (
        <ArchiveModalV1
          model={message}
          onClose={toggleArchiveModalOpen}
          onConfirm={archive}
          t={t}
          title={t('Archive Message')}
        />
      )}
      {isDeleteModalOpen && (
        <ArchiveModalV1
          message={t('Are you sure you want to delete this message? This action cannot be undone.')}
          method={'delete'}
          onClose={toggleDeleteModalOpen}
          onConfirm={destroy}
          t={t}
          title={t('Delete Message')}
        />
      )}
    </>
  );
}

export default EventPartnersMessagesOptions;
