import { useTranslation } from 'react-i18next';

interface IUseCampaignTextReturn {
  disableEmailRepublish: string;
  disableLookalikeDuplicate: string;
  disableStopOrRepublishPastEndDate: string;
  disableSingleSendStopStartEndDate: string;
  disableEdit: string;
}

function useCampaignText(): IUseCampaignTextReturn {
  const { t } = useTranslation();

  return {
    disableEmailRepublish: t(
      'Auto Send and Smart campaigns cannot be re-enabled and should be duplicated instead',
    ),
    disableLookalikeDuplicate: t(
      'This lookalike campaign type is outdated and can no longer be duplicated',
    ),
    disableStopOrRepublishPastEndDate: t(
      'This campaign can no longer be published or stopped because it is past the end date',
    ),
    disableSingleSendStopStartEndDate: t(
      'Single Send campaigns cannot be stopped past their start date',
    ),
    disableEdit: t('This campaign can no longer be edited'),
  };
}

export default useCampaignText;
