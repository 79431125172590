import { action, computed, makeObservable } from 'mobx';

import { concatPath } from '@feathr/hooks';
import type { Attributes, IBaseAttributes, Model, TConstraints } from '@feathr/rachis';
import { Collection, DisplayModel, isWretchError, wretch } from '@feathr/rachis';

import type { IBannersnackAttributes, IBannersnackClass } from './bannersnack';
import type { Campaign, FacebookCampaign, IGoogleAdsAdText } from './campaigns';
import { CampaignClass } from './campaigns';

export interface IDimensionDefinition {
  name: string;
  spec: string;
  width: number;
  height: number;
}

export const ValidDimensions: Record<string, IDimensionDefinition> = {
  '728x90': {
    name: 'Leaderboard',
    spec: 'leaderboard',
    width: 728,
    height: 90,
  },
  '300x50': {
    name: 'Mini-Leaderboard',
    spec: 'mini_leaderboard',
    width: 300,
    height: 50,
  },
  '300x250': {
    name: 'Medium Rectangle',
    spec: 'medium_rectangle',
    width: 300,
    height: 250,
  },
  '160x600': {
    name: 'Wide Skyscraper',
    spec: 'wide_skyscraper',
    width: 160,
    height: 600,
  },
  '300x600': {
    name: 'Filmstrip',
    spec: 'filmstrip',
    width: 300,
    height: 600,
  },
  '300x1050': {
    name: 'Portrait',
    spec: 'portrait',
    width: 300,
    height: 1050,
  },
  '970x90': {
    name: 'Pushdown',
    spec: 'pushdown',
    width: 970,
    height: 90,
  },
  '970x250': {
    name: 'Billboard',
    spec: 'billboard',
    width: 970,
    height: 250,
  },
  '320x50': {
    name: 'Pull',
    spec: 'pull',
    width: 320,
    height: 50,
  },
  '320x250': {
    name: 'Tablet Leaderboard',
    spec: 'tablet_leaderboard',
    width: 320,
    height: 250,
  },
  '320x480': {
    name: 'Mobile Interstitial',
    spec: 'mobile_interstitial',
    width: 320,
    height: 480,
  },
  '120x240': {
    name: 'Vertical Banner',
    spec: 'vertical_banner',
    width: 120,
    height: 240,
  },
  '120x600': {
    name: 'Skyscraper',
    spec: 'skyscraper',
    width: 120,
    height: 600,
  },
  '125x125': {
    name: 'Button',
    spec: 'button',
    width: 125,
    height: 125,
  },
  '200x200': {
    name: 'Small Square',
    spec: 'small_square',
    width: 200,
    height: 200,
  },
  '234x60': {
    name: 'Half Banner',
    spec: 'half_banner',
    width: 234,
    height: 60,
  },
  '120x20': {
    name: 'Feature Phone Small',
    spec: 'feature_phone_small',
    width: 120,
    height: 20,
  },
  '168x28': {
    name: 'Feature Phone Medium',
    spec: 'feature_phone_medium',
    width: 168,
    height: 28,
  },
  '216x36': {
    name: 'Feature Phone Large',
    spec: 'feature_phone_small',
    width: 216,
    height: 36,
  },
  '240x400': {
    name: 'Vertical Rectangle',
    spec: 'vertical_rectangle',
    width: 240,
    height: 400,
  },
  '250x250': {
    name: 'Square',
    spec: 'square',
    width: 250,
    height: 250,
  },
  '250x360': {
    name: 'Triple Widescreen',
    spec: 'triple_widescreen',
    width: 250,
    height: 360,
  },
  '320x100': {
    name: 'Mobile Pull',
    spec: 'mobile_pull',
    width: 320,
    height: 100,
  },
  '336x280': {
    name: 'Large Rectangle',
    spec: 'large_rectangle',
    width: 336,
    height: 280,
  },
  '468x60': {
    name: 'Banner',
    spec: 'banner',
    width: 468,
    height: 60,
  },
  '180x150': {
    name: 'Small Rectangle',
    spec: 'small_rectangle',
    width: 180,
    height: 150,
  },
  '0x0': {
    name: 'Video',
    spec: 'video',
    width: 0,
    height: 0,
  },
  '1280x720': {
    name: '720p HD Video',
    spec: '720p_video',
    width: 1280,
    height: 720,
  },
  '1920x1080': {
    name: '1080p HD Video',
    spec: '1080p_video',
    width: 1920,
    height: 1080,
  },
  '480x360': {
    name: '360p SD Video',
    spec: '360p_video',
    width: 480,
    height: 360,
  },
  '640x480': {
    name: '480p SD Video',
    spec: '480p_video',
    width: 640,
    height: 480,
  },
};

export const ValidFacebookDimensions: Record<string, IDimensionDefinition> = {
  '1200x628': {
    name: 'Facebook Standard',
    spec: 'facebook_standard',
    width: 1200,
    height: 628,
  },
  '1080x1080': {
    name: 'Facebook Standard Square',
    spec: 'facebook_standard_square',
    width: 1080,
    height: 1080,
  },
};

export const ValidFacebookVideoDimensions: Record<string, IDimensionDefinition> = {
  '1080x1080': {
    name: 'Facebook Standard Square',
    spec: 'facebook_standard_square',
    width: 1080,
    height: 1080,
  },
  '1920x1080': {
    name: '1080p Facebook Landscape Video',
    spec: '1080p_facebook_landscape_video',
    width: 1920,
    height: 1080,
  },
  '864x1080': {
    name: '1080p Facebook Vertical Video',
    spec: '1080p_facebook_vertical_video',
    width: 864,
    height: 1080,
  },
  '1080x1920': {
    name: '1080p Facebook Full Portrait Video',
    spec: '1080p_facebook_full_portrait_video',
    width: 1080,
    height: 1920,
  },
};

export enum CreativeClass {
  DisplayAdTag = 'Creative.DisplayCreative.AdTagCreative.AdTagRTBCreative',
  DisplayBannersnack = 'Creative.DisplayCreative.BannersnackRTBCreative',
  DisplayImage = 'Creative.DisplayCreative.ImageCreative.ImageRTBCreative',
  DisplayVideo = 'Creative.DisplayCreative.VideoCreative.VideoRTBCreative',
  FacebookImage = 'Creative.DisplayCreative.FacebookRTBCreative.FacebookImageRTBCreative',
  FacebookVideo = 'Creative.DisplayCreative.FacebookRTBCreative.FacebookVideoRTBCreative',
  PartnerMessageEmail = 'Creative.HtmlCreative.EmailCreative.PartnerMessageCreative',
  ReferralBanner = 'Creative.HtmlCreative.PageCreative.ReferralBannerCreative',
  ReferralEmail = 'Creative.HtmlCreative.EmailCreative.ReferralEmailCreative',
  ReferralPage = 'Creative.HtmlCreative.PageCreative.ReferralPageCreative',
  GoogleAd = 'Creative.DisplayCreative.GoogleAdsCreative.GoogleAdsTextCreative',
}

export enum ECallToActionType {
  LEARN_MORE = 'LEARN_MORE',
  SIGN_UP = 'SIGN_UP',
  BUY_NOW = 'BUY_NOW',
  GET_OFFER = 'GET_OFFER',
}

export interface ICallToActionType {
  id: ECallToActionType;
  name: string;
}

export function creativeTypeLabel(type: CreativeClass): string {
  // TODO: Add missing creatives labels
  const typeMap: Record<CreativeClass, string> = {
    [CreativeClass.DisplayAdTag]: 'Ad Tag Creative',
    [CreativeClass.DisplayBannersnack]: 'Banner Builder',
    [CreativeClass.DisplayImage]: 'Image Creative',
    [CreativeClass.DisplayVideo]: 'Video Creative',
    [CreativeClass.FacebookImage]: 'Facebook Image Creative',
    [CreativeClass.FacebookVideo]: 'Facebook Video Creative',
    [CreativeClass.PartnerMessageEmail]: '-',
    [CreativeClass.ReferralBanner]: 'Banner',
    [CreativeClass.ReferralEmail]: 'Email',
    [CreativeClass.ReferralPage]: 'Page',
    [CreativeClass.GoogleAd]: 'Google Ads',
  };
  return typeMap[type];
}

export const CallToActionTypes: ICallToActionType[] = [
  { id: ECallToActionType.SIGN_UP, name: 'Sign Up' },
  { id: ECallToActionType.BUY_NOW, name: 'Buy Now' },
  { id: ECallToActionType.GET_OFFER, name: 'Get Offer' },
  { id: ECallToActionType.LEARN_MORE, name: 'Learn More' },
];

export type TCreativeAuditStatus =
  | 'not audited'
  | 'pending'
  | 'approved'
  | 'rejected'
  | 'unauditable';

export interface ICreativeAuditStatus {
  auditor: string;
  status: TCreativeAuditStatus;
  reason: string;
}

export interface ICreativeAttributes extends IBaseAttributes {
  readonly id: string;
  readonly _cls: CreativeClass;
  readonly account: string;
  readonly date_created: string;
  readonly date_last_modified: string;
  readonly event: string;
  readonly parent: string;
  readonly template: string;
  readonly version: number;
  destination_url?: string;
  height?: number;
  is_enabled: boolean;
  mimetype: string;
  name: string;
  spec: string;
  stats: {
    num_users_active: number;
    flavors: {
      ad_view: number;
      ad_click: number;
      page_view: number;
      page_link_click: number;
      email_view: number;
      email_link_click: number;
    };
  };
  width?: number;
  alt_text?: string;
}

export interface IDisplayCreative extends ICreativeAttributes {
  readonly _cls:
    | CreativeClass.DisplayBannersnack
    | CreativeClass.DisplayImage
    | CreativeClass.DisplayVideo
    | CreativeClass.DisplayAdTag
    | CreativeClass.FacebookImage
    | CreativeClass.FacebookVideo;
  width: number;
  height: number;
  url: string;
}

export interface IAdTagBaseCreative extends IDisplayCreative {
  readonly _cls: CreativeClass.DisplayBannersnack | CreativeClass.DisplayAdTag;
  readonly preview_url: string;
  adtag: string;
  preview_adtag: string;
  url: never;
}

export interface IAdTagCreative extends IAdTagBaseCreative {
  readonly _cls: CreativeClass.DisplayAdTag;
  audit_statuses: ICreativeAuditStatus[];
}

export interface IBannersnackCreative extends IAdTagBaseCreative, IBannersnackAttributes {
  readonly _cls: CreativeClass.DisplayBannersnack;
  audit_statuses: ICreativeAuditStatus[];
}

export interface IImageCreative extends IDisplayCreative {
  readonly _cls: CreativeClass.DisplayImage;
  audit_statuses: ICreativeAuditStatus[];
}

export interface IVideoCreative extends IDisplayCreative {
  readonly _cls: CreativeClass.DisplayVideo;
  skippable: boolean;
  audit_statuses: ICreativeAuditStatus[];
}

export interface IGoogleAdsCreative extends ICreativeAttributes {
  readonly _cls: CreativeClass.GoogleAd;
  descriptions: IGoogleAdsAdText[];
  headlines: IGoogleAdsAdText[];
}

export type TFacebookCreativeAuditStatus =
  | 'WITH_ISSUES'
  | 'DISAPPROVED'
  | 'PENDING_REVIEW'
  | 'IN_PROCESS'
  | 'ACTIVE'
  | 'PAUSED'
  | 'ARCHIVED';

// Add inline comments to each of the elements below
export interface IFacebookAdPreviewRequest {
  /** The format of the ad to be displayed */
  ad_format: string;
  /** The class identifier for the ad preview request */
  _cls: string;
  /** The Facebook Page ID associated with the campaign */
  facebook_page_id: string;
  /** The message text to be displayed in the ad */
  message: string;
  /** The name of the ad */
  name: string;
  /** The caption text to be displayed below the ad's media */
  caption: string;
  /** The description text providing more details about the ad */
  description: string;
  /** The URL of the ad's media content */
  url: string;
  /** The URL where the user will be redirected after clicking the ad */
  destination_url: string;
  /** The type of call-to-action text to be displayed on the ad */
  call_to_action_type: string;
}

export interface IFacebookVideoAdPreviewRequest extends IFacebookAdPreviewRequest {
  /** The ad video ID according to Meta */
  ad_video_id: string;
  /** The ad video thumbnail URI */
  ad_video_thumbnail: string;
}

export interface IFacebookImageAdPreviewRequest extends IFacebookAdPreviewRequest {
  /** The Instagram account ID associated with the campaign */
  instagram_actor_id: string;
}

export interface IFacebookAdReviewFeedback {
  /*
   * We are not aware of all the possible keys for the
   * feedback attribute
   */
  feedback?: Record<string, string>;
  facebook?: Record<string, string>;
  instagram?: Record<string, string>;
}
export interface IFacebookDisplayCreative extends IDisplayCreative {
  description: string;
  message: string;
  caption: string;
  call_to_action_type: ECallToActionType;
  ad_status: TFacebookCreativeAuditStatus;
  ad_review_feedback: IFacebookAdReviewFeedback;
}

export interface IFacebookImageCreative extends IFacebookDisplayCreative {
  readonly _cls: CreativeClass.FacebookImage;
}

export interface IFacebookVideoCreative extends IFacebookDisplayCreative {
  readonly _cls: CreativeClass.FacebookVideo;
  skippable: boolean;
  ad_video_id: string;
  ad_video_thumbnail: string;
  duration?: number;
}

export interface IDisplayable {
  creatives: IDisplayCreative[];
}

const displayCreativeConstraints: TConstraints<IDisplayCreative> = {
  name: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[^;<>^]*$/,
      message: 'cannot contain any of the following: <>;^',
    },
  },
  spec: {
    presence: (...args: any[]) => {
      const attributes = args[1];
      if (
        !attributes.width ||
        !attributes.height ||
        attributes.width < 1 ||
        attributes.height < 1
      ) {
        return {
          message: `^A set of creative dimensions must be selected`,
        };
      }
      return {
        message: `^${attributes.width}px x ${attributes.height}px is not a valid set of creative dimensions`,
      };
    },
  },
  width: {
    numericality: true,
    presence: {
      allowEmpty: false,
    },
  },
  height: {
    numericality: true,
    presence: {
      allowEmpty: false,
    },
  },
  url: {
    presence: {
      allowEmpty: false,
    },
    url: true,
  },
  destination_url: {
    url: (...args: any[]) => {
      const attributes = args[1];
      const isEmpty = !attributes.destination_url;
      if (isEmpty) {
        return undefined;
      }
      return true;
    },
  },
};

export abstract class Creative<
  T extends ICreativeAttributes = ICreativeAttributes,
> extends DisplayModel<T> {
  // TODO: add functionality to automatically set spec from width and height

  public get constraints(): TConstraints<T> {
    return displayCreativeConstraints;
  }

  constructor(attributes: Partial<T>) {
    super(attributes);

    makeObservable(this);
  }

  @computed
  public get name(): string {
    return this.get('name', '' as Exclude<T['name'], undefined>).trim() || 'Unnamed Creative';
  }

  public override getDefaults(): Partial<T> {
    return {
      stats: {
        flavors: {
          ad_click: 0,
          ad_view: 0,
          page_view: 0,
          page_link_click: 0,
          email_view: 0,
          email_link_click: 0,
        },
        num_users_active: 0,
      },
    } as Partial<T>;
  }

  public getItemUrl(pathSuffix?: string): string {
    return concatPath(`/projects/${this.get('event')}/content/creatives/${this.id}`, pathSuffix);
  }

  public getSpec(): IDimensionDefinition | undefined {
    const key =
      Object.keys(ValidDimensions).find(
        (dimensionKey) => ValidDimensions[dimensionKey].spec === this.get('spec'),
      ) ?? `${this.get('width')}x${this.get('height')}`;
    if (ValidDimensions.hasOwnProperty(key)) {
      return ValidDimensions[key];
    }
    return undefined;
  }

  public getTypeLabel(): string {
    const spec = this.getSpec();
    if (spec) {
      return spec.name;
    }
    return creativeTypeLabel(this.get('_cls'));
  }

  public getValidDimensions(campaign: Campaign): Record<string, IDimensionDefinition> {
    const isVideo = this.get('mimetype') && this.get('mimetype').includes('video');
    if (campaign.get('_cls') === CampaignClass.MobileGeoFencing && !isVideo) {
      const mobileDimensions = ['300x250', '320x50', '300x50', '336x280', '320x480'];
      return Object.fromEntries(
        Object.entries(ValidDimensions).filter(([key]) => mobileDimensions.includes(key)),
      );
    }
    return Object.fromEntries(
      Object.entries(ValidDimensions).filter(([, value]) =>
        isVideo ? value.spec.includes('video') : !value.spec.includes('video'),
      ),
    );
  }
}

export class BaseCreative extends Creative<ICreativeAttributes> {
  public readonly className = 'BaseCreative';

  public override get constraints(): TConstraints<ICreativeAttributes> {
    return super.constraints;
  }
}

export type TPartnerMessageEmailCreative = ICreativeAttributes;

export class PartnerMessageEmailCreative extends Creative<TPartnerMessageEmailCreative> {
  public readonly className = 'PartnerMessageEmailCreative';

  public override get constraints(): TConstraints<TPartnerMessageEmailCreative> {
    return super.constraints;
  }

  constructor(attributes: Partial<TPartnerMessageEmailCreative>) {
    super(attributes);

    makeObservable(this);
  }

  public override get name(): string {
    return this.get('name', '').trim() || 'Unnamed Creative';
  }
}

export type TReferralPageCreative = ICreativeAttributes & {
  participant: string;
  redirect_url: string;
};

export class ReferralPageCreative extends Creative<TReferralPageCreative> {
  public readonly className = 'ReferralPageCreative';

  public override get constraints(): TConstraints<TReferralPageCreative> {
    return super.constraints;
  }

  constructor(attributes: Partial<TReferralPageCreative>) {
    super(attributes);

    makeObservable(this);
  }
}

export type TReferralBannerCreative = ICreativeAttributes & {
  participant: string;
  width: number;
  height: number;
  destination_url: string;
  render_ad_tag: string;
  banner_hash?: string;
  banner_urls?: Array<Record<string, string>>;
};

export class ReferralBannerCreative extends Creative<TReferralBannerCreative> {
  public readonly className = 'ReferralBannerCreative';

  public override get constraints(): TConstraints<TReferralBannerCreative> {
    return super.constraints;
  }

  constructor(attributes: Partial<TReferralBannerCreative>) {
    super(attributes);

    makeObservable(this);
  }

  public override get name(): string {
    return this.get('name', '').trim() || 'Unnamed Creative';
  }

  public getBannerLink(type: string): string | undefined {
    const links = this.get('banner_urls') || [];
    const link = links.find((l) => l.type === type && l.status === 'done');
    if (link) {
      return link.url;
    }
    return undefined;
  }

  public async sync(): Promise<void> {
    this.assertCollection(this.collection);
    const response = await wretch<Partial<TReferralBannerCreative>>(
      `${this.collection.url()}${this.id}/sync`,
      {
        method: 'GET',
        headers: this.collection.getHeaders(),
      },
    );

    if (isWretchError(response)) {
      throw response.error;
    }
    this.set({
      banner_urls: response.data.banner_urls,
    });
  }
}

export type TReferralEmailCreative = ICreativeAttributes & {
  participant: string;
};

export class ReferralEmailCreative extends Creative<TReferralEmailCreative> {
  public readonly className = 'ReferralEmailCreative';

  public override get constraints(): TConstraints<TReferralEmailCreative> {
    return super.constraints;
  }

  constructor(attributes: Partial<TReferralEmailCreative>) {
    super(attributes);

    makeObservable(this);
  }

  public override get name(): string {
    return this.get('name', '').trim() || 'Unnamed Creative';
  }
}

export class DisplayCreative<T extends IDisplayCreative = IDisplayCreative> extends Creative<T> {
  public readonly className:
    | 'AdTagCreative'
    | 'BannersnackCreative'
    | 'DisplayCreative'
    | 'FacebookDisplayCreative'
    | 'FacebookImageCreative'
    | 'FacebookVideoCreative' = 'DisplayCreative';

  public override get constraints(): TConstraints<T> {
    return super.constraints;
  }

  public closestValidDimension(dimensions: Record<string, IDimensionDefinition>): string {
    let d = Infinity;
    let spec = 'None (0px x 0px)';
    const width = this.get('width');
    const height = this.get('height');
    Object.values(dimensions).forEach((dim) => {
      const dp = Math.sqrt((width - dim.width) ** 2 + (height - dim.height) ** 2);
      if (dp < d) {
        d = dp;
        spec = `${dim.name} (${dim.width}px x ${dim.height}px)`;
      }
    });
    return spec;
  }
}

export class ImageCreative extends Creative<IImageCreative> {
  public readonly className = 'ImageCreative';

  public override get constraints(): TConstraints<IImageCreative> {
    return super.constraints;
  }
}

export class VideoCreative extends Creative<IVideoCreative> {
  public readonly className = 'VideoCreative';

  public override get constraints(): TConstraints<IVideoCreative> {
    return super.constraints;
  }
}

export abstract class FacebookDisplayCreative<
  T extends IFacebookDisplayCreative = IFacebookDisplayCreative,
> extends DisplayCreative<T> {
  public override readonly className:
    | 'BannersnackCreative'
    | 'FacebookDisplayCreative'
    | 'FacebookImageCreative'
    | 'FacebookVideoCreative' = 'FacebookDisplayCreative';

  public override get constraints(): TConstraints<T> {
    return {
      ...super.constraints,
      message: {
        length: {
          maximum: 1000,
        },
      },
      description: {
        length: {
          maximum: 27,
        },
      },
      caption: {
        url: (...args: any[]) => {
          const attributes = args[1];
          const isEmpty = !attributes.caption;
          if (isEmpty) {
            return undefined;
          }
          return true;
        },
        length: {
          maximum: 25,
        },
      },
      call_to_action_type: {
        inclusion: {
          within: [
            ECallToActionType.BUY_NOW,
            ECallToActionType.GET_OFFER,
            ECallToActionType.LEARN_MORE,
            ECallToActionType.SIGN_UP,
          ],
        },
      },
    };
  }

  public override getDefaults(): Partial<T> {
    return {
      call_to_action_type: 'LEARN_MORE',
      stats: {
        flavors: {
          ad_click: 0,
          ad_view: 0,
          page_view: 0,
          page_link_click: 0,
          email_view: 0,
          email_link_click: 0,
        },
        num_users_active: 0,
      },
    } as Partial<T>;
  }

  public abstract getFacebookAdPreview(
    adFormat: string,
    campaign: FacebookCampaign,
  ): Promise<string>;

  public override getSpec(): IDimensionDefinition | undefined {
    const key = `${this.get('width')}x${this.get('height')}`;
    if (ValidFacebookDimensions.hasOwnProperty(key)) {
      return ValidFacebookDimensions[key];
    }
    return undefined;
  }

  public override getValidDimensions(): Record<string, IDimensionDefinition> {
    return ValidFacebookDimensions;
  }

  public async submitReviewRequest(): Promise<Record<string, unknown>> {
    this.assertCollection(this.collection);
    const url = `${this.collection.getHostname()}integrations/facebook/ad_request_review/${
      this.id
    }`;
    const response = await wretch<ICreativeAttributes>(url, {
      method: 'PATCH',
      headers: this.collection.getHeaders(),
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }
}

export class FacebookImageCreative extends FacebookDisplayCreative<IFacebookImageCreative> {
  public override readonly className = 'FacebookImageCreative';

  public async getFacebookAdPreview(adFormat: string, campaign: FacebookCampaign): Promise<string> {
    this.assertCollection(this.collection);

    const attributes: IFacebookImageAdPreviewRequest = {
      ad_format: adFormat,
      _cls: this.get('_cls'),
      facebook_page_id: campaign.get('facebook_page_id'),
      instagram_actor_id: campaign.get('instagram_actor_id'),
      message: this.get('message'),
      name: this.get('name'),
      caption: this.get('caption'),
      description: this.get('description'),
      url: this.get('url'),
      destination_url: this.get('destination_url') || campaign.get('destination_url'),
      call_to_action_type: this.get('call_to_action_type'),
    };

    const url = `${this.collection.getHostname()}integrations/facebook/ad_preview`;
    const response = await wretch<{ body: string }>(url, {
      method: 'POST',
      body: JSON.stringify(attributes),
      headers: this.collection.getHeaders(),
    });
    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data.body;
  }
}

export class FacebookVideoCreative extends FacebookDisplayCreative<IFacebookVideoCreative> {
  public override readonly className = 'FacebookVideoCreative';
  public override get constraints(): TConstraints<IFacebookVideoCreative> {
    return {
      ...super.constraints,
      duration: (_: string, attributes: Partial<IFacebookVideoCreative>) => {
        if (attributes.mimetype?.includes('video')) {
          return {
            numericality: {
              greaterThanOrEqualTo: 5,
              lessThanOrEqualTo: 120,
            },
          };
        }
        return {
          presence: {
            allowEmpty: true,
          },
        };
      },
      ad_video_thumbnail: {
        url: true,
      },
      ad_video_id: {
        presence: {
          allowEmpty: false,
        },
      },
    };
  }

  public override getSpec(): IDimensionDefinition | undefined {
    const key = `${this.get('width')}x${this.get('height')}`;
    if (ValidFacebookVideoDimensions.hasOwnProperty(key)) {
      return ValidFacebookVideoDimensions[key];
    }
    return undefined;
  }

  public override getValidDimensions(): Record<string, IDimensionDefinition> {
    return ValidFacebookVideoDimensions;
  }

  public async getFacebookAdPreview(adFormat: string, campaign: FacebookCampaign): Promise<string> {
    this.assertCollection(this.collection);

    const attributes: IFacebookVideoAdPreviewRequest = {
      ad_format: adFormat,
      _cls: this.get('_cls'),
      facebook_page_id: campaign.get('facebook_page_id'),
      message: this.get('message'),
      name: this.get('name'),
      caption: this.get('caption'),
      description: this.get('description'),
      url: this.get('url'),
      destination_url: this.get('destination_url') || campaign.get('destination_url'),
      call_to_action_type: this.get('call_to_action_type'),
      ad_video_id: this.get('ad_video_id'),
      ad_video_thumbnail: this.get('ad_video_thumbnail'),
    };

    const url = `${this.collection.getHostname()}integrations/facebook/ad_preview`;
    const response = await wretch<{ body: string }>(url, {
      method: 'POST',
      body: JSON.stringify(attributes),
      headers: this.collection.getHeaders(),
    });
    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data.body;
  }
}

export class GoogleAdsCreative extends Creative<IGoogleAdsCreative> {
  public readonly className = 'GoogleAdsCreative';

  public override get constraints(): TConstraints<IGoogleAdsCreative> {
    // Google Ads creatives do not follow inheritance rules re constraints
    return {};
  }
}

export class AdTagCreative extends DisplayCreative<IAdTagCreative> {
  public override readonly className = 'AdTagCreative';

  public override get constraints(): TConstraints<IAdTagCreative> {
    return {
      ...super.constraints,
      adtag: {
        presence: {
          allowEmpty: false,
        },
      },
      url: undefined,
    };
  }
}

interface IBannersnackCreativeEditorUrlResponse extends Record<string, unknown> {
  url: string;
}

export class BannersnackCreative
  extends DisplayCreative<IBannersnackCreative>
  implements IBannersnackClass
{
  public override readonly className = 'BannersnackCreative';

  public override get constraints(): TConstraints<IBannersnackCreative> {
    return {
      ...super.constraints,
      url: undefined,
    };
  }

  public async editorURL(): Promise<string> {
    this.assertCollection(this.collection);

    const response = await wretch<IBannersnackCreativeEditorUrlResponse>(
      `${this.collection.url()}${this.id}/editor`,
      {
        method: 'GET',
        headers: this.collection.getHeaders(),
      },
    );
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data.url;
  }

  public async sync(timestamp: number): Promise<void> {
    this.assertCollection(this.collection);

    this.isUpdating = true;
    const response = await wretch<Partial<IBannersnackCreative>>(
      `${this.collection.url()}${this.id}/sync?ts=${timestamp}`,
      {
        method: 'GET',
        headers: this.collection.getHeaders(),
      },
    );
    if (isWretchError(response)) {
      throw response.error;
    }
    if (!this.get('banner_hash')) {
      this.set({ banner_hash: response.data.banner_hash });
    }
    this.set({
      adtag: response.data.adtag,
      animated: response.data.animated,
      banner_urls: response.data.banner_urls,
      height: response.data.height,
      name: response.data.name,
      width: response.data.width,
    });
    this.isUpdating = false;
  }
}

export function disambiguateCreative(attributes: any): TCreative {
  switch (attributes._cls) {
    case CreativeClass.DisplayAdTag:
      return new AdTagCreative(attributes);

    case CreativeClass.DisplayBannersnack:
      return new BannersnackCreative(attributes);

    case CreativeClass.DisplayImage:

    case CreativeClass.DisplayVideo:
      return new DisplayCreative(attributes);

    case CreativeClass.FacebookImage:
      return new FacebookImageCreative(attributes);

    case CreativeClass.FacebookVideo:
      return new FacebookVideoCreative(attributes);

    case CreativeClass.GoogleAd:
      return new GoogleAdsCreative(attributes);

    case CreativeClass.PartnerMessageEmail:
      return new PartnerMessageEmailCreative(attributes);

    case CreativeClass.ReferralBanner:
      return new ReferralBannerCreative(attributes);

    case CreativeClass.ReferralEmail:
      return new ReferralEmailCreative(attributes);

    case CreativeClass.ReferralPage:
      return new ReferralPageCreative(attributes);

    default:
      return new BaseCreative(attributes);
  }
}

export type TCreative =
  | AdTagCreative
  | BannersnackCreative
  | BaseCreative
  | DisplayCreative
  | FacebookImageCreative
  | FacebookVideoCreative
  | GoogleAdsCreative
  | ImageCreative
  | PartnerMessageEmailCreative
  | ReferralBannerCreative
  | ReferralEmailCreative
  | ReferralPageCreative
  | VideoCreative;

export class Creatives extends Collection<TCreative> {
  @action.bound
  public getModel(attributes: Partial<Attributes<Model>> = {}): TCreative {
    return disambiguateCreative(attributes);
  }

  public getClassName(): string {
    return 'creatives';
  }
}
