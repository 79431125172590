import { makeObservable } from 'mobx';

import type { TConstraints } from '@feathr/rachis';

import { Campaign } from './campaign';
import type { TConversationCampaign } from './types';

export class ConversationCampaign extends Campaign<TConversationCampaign> {
  public override get constraints(): TConstraints<TConversationCampaign> {
    return {
      // ...super.constraints,
      url: {
        presence: {
          allowEmpty: false,
        },
        search: {
          pattern: /^[^*]*$/,
          message: 'must not contain *',
        },
      },
    };
  }

  constructor(attributes: Partial<TConversationCampaign> = {}) {
    super(attributes);

    makeObservable(this);
  }

  public override getDefaults(): Partial<TConversationCampaign> {
    return {
      ...super.getDefaults(),
      url: '',
    };
  }

  public override get name(): string {
    const customName = this.get('name')?.trim() ?? '';
    const firstQuestionText = this.get('questions')[0]?.text?.trim();

    if (customName) {
      return customName;
    }

    if (firstQuestionText) {
      return firstQuestionText;
    }

    return 'Unnamed Conversation Campaign';
  }
}
