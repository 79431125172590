import type { JSX } from 'react';
import React from 'react';
import type { ActionMeta, ValueType } from 'react-select';

import type { IBreadcrumbSuggestion, IFetchPersonFieldValue } from '@feathr/blackbox';
import { Session } from '@feathr/blackbox';
import { AsyncSelect } from '@feathr/components';
import { isWretchError, wretch } from '@feathr/rachis';

import { select as selectCN } from './PredicateListValueSelect.css';

interface IProps {
  disabled?: boolean;
  getPath: () => string;
  onChange: (value: string[]) => void;
  placeholder: string;
  name: string;
  value: string[];
}

interface IState {
  inputValue: string;
}

interface IValue {
  value: string;
  label: string;
}

const getHeaders = () => Session.getHeaders();

// TODO: Refactor into function component.
export default class PredicateListValueSelect extends React.Component<IProps, IState> {
  public override state = { inputValue: '' };

  public onInputChange = (inputValue: string): string => {
    this.setState({ inputValue });
    return inputValue;
  };

  public onChange = (selected: ValueType<IValue>, action: ActionMeta<IValue>): void => {
    if (['select-option', 'remove-value', 'clear'].includes(action.action)) {
      if (Array.isArray(selected)) {
        this.props.onChange(selected.map((val) => val.value));
      } else if (selected) {
        this.props.onChange([(selected as IValue).value]);
      } else {
        this.props.onChange([]);
      }
    }
  };

  public loadOptions = async (inputValue: string): Promise<IValue[]> => {
    const { getPath } = this.props;
    const path = getPath();
    const headers = getHeaders();
    const url = `${BLACKBOX_URL}${path}/?q=${encodeURIComponent(inputValue || '')}`;
    const response = await wretch<IBreadcrumbSuggestion[] | IFetchPersonFieldValue[]>(url, {
      headers,
      method: 'GET',
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data.map((fieldValue) => ({
      value: fieldValue.value,
      label: fieldValue.value,
    }));
  };

  private getOptionLabel({ label }: IValue): string {
    return label;
  }

  private getOptionValue({ value }: IValue): string {
    return value;
  }

  public override render(): JSX.Element {
    const { disabled, placeholder, name, value } = this.props;
    return (
      <AsyncSelect
        cacheOptions={true}
        className={selectCN}
        defaultOptions={true}
        disabled={disabled}
        getOptionLabel={this.getOptionLabel}
        getOptionValue={this.getOptionValue}
        isMulti={true}
        loadOptions={this.loadOptions}
        name={name}
        onChange={this.onChange}
        onInputChange={this.onInputChange}
        placeholder={placeholder}
        styles={{
          menu: (provided) => ({ ...provided, width: 300 }),
        }}
        value={
          value
            ? value.map((v) => ({
                value: v,
                label: v,
              }))
            : null
        }
      />
    );
  }
}
