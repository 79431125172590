import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { Breadcrumb, IBreadcrumb, Segment } from '@feathr/blackbox';
import { FieldCollection } from '@feathr/blackbox';
import { Table, toast } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';
import type { IListParams } from '@feathr/rachis';

import { filterBreadcrumbColumns } from './BreadcrumbColumns';
import DataConfigure from './DataCustomize';
import RefreshController from './RefreshController';

const defaultBreadcrumbColumnIds = ['d_c', 'per_id', 'flvr', 'device', 'loc_url', 'location'];

interface IProps {
  segment: Segment;
}

function BreadcrumbsTable({ segment }: IProps): JSX.Element {
  const { CustomFields, Breadcrumbs } = useContext(StoresContext);
  const { t } = useTranslation();

  const [columnIds, setColumnIds] = useState(defaultBreadcrumbColumnIds);
  const [reset, setReset] = useState(false);
  const [timeout, setTimeout] = useState<number | undefined>();
  const [shouldAutoRefresh, toggleAutoRefresh] = useToggle(false);

  useEffect(() => {
    // Stop resetting as soon as we have reloaded.
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    if (!shouldAutoRefresh) {
      return;
    }

    // TODO: Create reusable hook for polling
    function poll(): void {
      setReset(true);
      // 60000ms = 1min, that's how often we want to auto-refresh the table
      setTimeout(window.setTimeout(poll, 60000, setReset, setTimeout));
    }

    if (timeout === undefined) {
      try {
        poll();
      } catch (e) {
        toast(t('There was an error updating your breadcrumbs. Please refresh the page.'), {
          type: ToastType.ERROR,
        });
      }
    }
    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  }, [t, timeout, setTimeout, shouldAutoRefresh]);

  const fields = CustomFields.list({
    filters: {
      collection: FieldCollection.Breadcrumb,
    },
  });

  function handleRefresh(): void {
    setReset(true);
  }

  const filterElements = [
    undefined,
    <>
      <DataConfigure columnIds={columnIds} mode={'breadcrumbs'} onChange={setColumnIds} />
      <RefreshController
        handleRefresh={handleRefresh}
        shouldRefresh={shouldAutoRefresh}
        toggleRefresh={toggleAutoRefresh}
      />
    </>,
  ];

  return (
    <Table<Breadcrumb>
      collection={Breadcrumbs}
      columns={filterBreadcrumbColumns(columnIds, fields.models, t)}
      filterElements={filterElements}
      initialSort={[{ id: 'd_c', desc: true }]}
      listOptions={{ reset }}
      params={
        {
          predicates: toJS(segment.get('predicates', [])),
          mode: segment.get('mode'),
          lookback_mode: segment.get('lookback_mode'),
          lookback_value: segment.get('lookback_value'),
        } as IListParams<IBreadcrumb>
      }
    />
  );
}

export default observer(BreadcrumbsTable);
