import { useContext } from 'react';

import type { User } from '@feathr/blackbox';
import { Session } from '@feathr/blackbox';

import { StoresContext } from '.';

class NoUserError extends Error {
  public __proto__: Error;
  constructor(message = 'No user context available.') {
    const trueProto = new.target.prototype;
    super(message);
    this.__proto__ = trueProto;
  }

  public override toString(): string {
    return `NoUserError: ${this.message}`;
  }
}

function assertUser(user: User | undefined): asserts user {
  if (user === undefined || user.isEphemeral || user.isErrored) {
    throw new NoUserError();
  }
}

interface IUserOptions {
  required: boolean;
}

function useUser(): User;
function useUser(options: { required: true }): User;
function useUser(options: { required: false }): User | undefined;
function useUser(options: IUserOptions = { required: true }): User | undefined {
  let user: User | undefined;
  const { required } = options;
  const { Users } = useContext(StoresContext);
  if (Session.userId) {
    localStorage.setItem('userId', Session.userId!);
    user = Users.get(Session.userId, undefined, undefined, undefined, (user) => {
      localStorage.setItem('userName', user.name);
      localStorage.setItem('userEmail', user.get('email'));
    });
  }

  if (required) {
    assertUser(user);
  }

  return user;
}

export default useUser;
export { NoUserError };
