import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign } from '@feathr/blackbox';
import { ContextMenu } from '@feathr/components';
import { useCampaign } from '@feathr/extender/hooks';
import { useLocalUrl } from '@feathr/extender/state';
import { getIconForAction } from '@feathr/hooks';

import CampaignArchiveModal from '../CampaignArchiveModal';
import CampaignDeleteModal from '../CampaignDeleteModal';
import CampaignDuplicateModal from '../CampaignDuplicateModal';
import CampaignRemoveFromFlightModal from '../CampaignRemoveFromFlightModal';
import CampaignStopModal from '../CampaignStopModal';

interface ICampaignOptions {
  campaign: Campaign;
  showFlightOptions?: boolean;
}

function CampaignOptions({ campaign, showFlightOptions = false }: ICampaignOptions): JSX.Element {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  const { disabled, allowed, modalHandlers, modalStates, text } = useCampaign({
    campaign,
  });

  return (
    <>
      <ContextMenu buttonType={'icon'} position={'left-start'}>
        {/* Only show menu heading labels if it's a flight, since that's the only
            place that needs extra context */}
        {showFlightOptions && <ContextMenu.Label>{t('Campaign')}</ContextMenu.Label>}
        <ContextMenu.Item
          link={localUrl(campaign.getItemUrl('edit'))}
          prefix={getIconForAction('edit')}
        >
          {t('Edit')}
        </ContextMenu.Item>
        <ContextMenu.Item
          disabled={disabled.duplicate}
          onClick={modalHandlers.openDuplicateModal}
          prefix={getIconForAction('duplicate')}
          tooltip={text.disableDuplicate}
        >
          {t('Duplicate')}
        </ContextMenu.Item>
        {allowed.stop && (
          <ContextMenu.Item
            disabled={disabled.stop}
            onClick={modalHandlers.openStopModal}
            prefix={getIconForAction('stop')}
            theme={'danger'}
            tooltip={text.disableStop}
          >
            {t('Stop')}
          </ContextMenu.Item>
        )}
        {/* Drafts can be deleted, published campaigns can be archived */}
        {allowed.delete ? (
          <ContextMenu.Item
            onClick={modalHandlers.openDeleteModal}
            prefix={getIconForAction('delete')}
            theme={'danger'}
          >
            {t('Delete')}
          </ContextMenu.Item>
        ) : (
          <ContextMenu.Item
            onClick={modalHandlers.openArchiveModal}
            prefix={getIconForAction('archive')}
            theme={'danger'}
          >
            {t('Archive')}
          </ContextMenu.Item>
        )}
        {showFlightOptions && (
          <>
            <ContextMenu.Divider />
            <ContextMenu.Label>{t('Flight')}</ContextMenu.Label>
            <ContextMenu.Item
              onClick={modalHandlers.openRemoveFromFlightModal}
              prefix={getIconForAction('remove')}
              theme={'danger'}
            >
              {t('Remove from flight')}
            </ContextMenu.Item>
          </>
        )}
      </ContextMenu>

      <CampaignDuplicateModal
        campaign={campaign}
        close={modalHandlers.closeDuplicateModal}
        opened={modalStates.isDuplicateModalOpen}
      />

      <CampaignStopModal
        campaign={campaign}
        close={modalHandlers.closeStopModal}
        opened={modalStates.isStopModalOpen}
      />

      <CampaignArchiveModal
        campaign={campaign}
        closeArchiveModal={modalHandlers.closeArchiveModal}
        isArchiveModalOpen={modalStates.isArchiveModalOpen}
      />

      <CampaignDeleteModal
        campaign={campaign}
        closeDeleteModal={modalHandlers.closeDeleteModal}
        isDeleteModalOpen={modalStates.isDeleteModalOpen}
      />

      <CampaignRemoveFromFlightModal
        campaign={campaign}
        closeRemoveFromFlightModal={modalHandlers.closeRemoveFromFlightModal}
        isRemoveFromFlightModalOpen={modalStates.isRemoveFromFlightModalOpen}
      />
    </>
  );
}

export default CampaignOptions;
