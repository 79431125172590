import { ObjectId } from 'bson';
import type { TFunction, WithT } from 'i18next';
import type { IObservableArray } from 'mobx';
import { when } from 'mobx';

import {
  type Account,
  type DripCampaign,
  type DripStepCampaign,
  type EmailVerifications,
  EPinpointRequestStatus,
  type Goal,
  type ICampaignSegment,
  type IDripCampaignStepSpec,
  type Segment,
} from '@feathr/blackbox';

import type { ICampaignValidationErrors } from '../../CampaignSummary';
import { validateStepGoals } from '../StepGoals';

export function createPlaceholderStep(t: TFunction, key?: string): IDripCampaignStepSpec {
  return {
    key: key ?? new ObjectId().toHexString(),
    name: t('Untitled'),
    is_valid: false,
  };
}

const shared = ['from_address', 'subject', 'template_id', 'delay_unit', 'delay_value'];
const sender = [
  'from_name',
  'address.premise1',
  'address.locality',
  'address.administrative_area_name',
  'address.postal_code',
  'address.country_code',
];

interface IValidationProps {
  campaign: DripStepCampaign;
  includeName?: boolean;
  isVerifiedSender?: boolean;
}

export function validateInitialEnrollment({
  campaign,
  includeName = true,
  isVerifiedSender,
}: IValidationProps): ICampaignValidationErrors {
  const attributes = [
    'actions',
    'cooldown_value',
    'cooldown_unit',
    'delay_unit',
    'repeat',
    'time_to_send',
    ...shared,
  ];

  // Step name should be validated separately.
  if (includeName) {
    attributes.push('name');
  }

  // If false or undefined, we don't want to validate on these fields.
  if (isVerifiedSender) {
    attributes.push(...sender);
  }
  return campaign.validate(attributes, false, 'grouped').errors;
}

export function validateAutomation({
  campaign,
  includeName = true,
  isVerifiedSender,
}: IValidationProps): ICampaignValidationErrors {
  const attributes = ['set_action', ...shared];

  // Step name should be validated separately.
  if (includeName) {
    attributes.push('name');
  }

  // If false or undefined, we don't want to validate on these fields.
  if (isVerifiedSender) {
    attributes.push(...sender);
  }

  return campaign.validate(attributes, false, 'grouped').errors;
}

export function validateDetails(campaign: DripCampaign): ICampaignValidationErrors {
  return campaign.validate(
    ['date_send_start', 'date_send_end', 'consent.has_consent', 'name'],
    false,
    'grouped',
  ).errors;
}

export async function getUnverifiedFromAddresses(
  campaigns: IDripCampaignStepSpec[],
  EmailVerifications: EmailVerifications,
): Promise<string[]> {
  const fromAddresses = campaigns.reduce((acc, campaign) => {
    if (campaign.from_address !== undefined) {
      acc.push(campaign.from_address);
    }
    return acc;
  }, []);

  // does this need await?
  const results = EmailVerifications.list({
    filters: {
      email__in: fromAddresses,
    },
  });
  await when(() => !results.isPending);
  const emails = results.models;
  const unverified = campaigns.reduce((acc, { from_address: fromAddress }) => {
    if (fromAddress !== undefined) {
      const email = emails.find((e) => e.get('email') === fromAddress);
      if (email?.get('status') !== EPinpointRequestStatus.Success) {
        acc.push(fromAddress);
      }
    }
    return acc;
  }, [] as string[]);
  return [...new Set(unverified)];
}

export const validateGroupExclusions = (
  segments: IObservableArray<ICampaignSegment>,
): ICampaignValidationErrors => {
  return {
    exclusions: segments.some((s) => !s.id)
      ? ['Must provide a valid group or remove the exclusion']
      : [],
  };
};

interface IValidationDetails extends WithT {
  account: Account;
  stepOneErrors: string[];
  campaign: DripCampaign;
  goals: IObservableArray<Goal>;
  goalSegments?: Segment[];
  groupExclusions: IObservableArray<ICampaignSegment>;
}

export function validate({
  account,
  campaign,
  stepOneErrors,
  goals,
  goalSegments,
  groupExclusions,
  t,
}: IValidationDetails): ICampaignValidationErrors {
  const stepTwoErrors = validateGroupExclusions(groupExclusions);
  const stepThreeErrors = validateStepGoals(goals, goalSegments);
  const stepFourErrors = validateDetails(campaign);

  const validationErrors: ICampaignValidationErrors = {
    steps: stepOneErrors,
    exclusions: stepTwoErrors.exclusions ?? [],
    goals: stepThreeErrors.goals ?? [],
    name: (stepFourErrors.name as string[]) ?? [],
    date_send_start: (stepFourErrors.date_send_start as string[]) ?? [],
    date_send_end: (stepFourErrors.date_send_end as string[]) ?? [],
    'consent.has_consent': stepFourErrors['consent.has_consent'] ?? [],
  };

  if (account.get('email_health') === 'suspended') {
    validationErrors.account = [
      t('Your Feathr account is suspended and cannot publish any new email campaigns.'),
    ];
  }

  return validationErrors;
}
