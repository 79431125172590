import { makeObservable } from 'mobx';

import type { TConstraints } from '@feathr/rachis';

import { Campaign } from './campaign';
import type { IEmailBaseCampaign } from './types';

export abstract class EmailBaseCampaign<
  T extends IEmailBaseCampaign = IEmailBaseCampaign,
> extends Campaign<T> {
  public override get constraints(): TConstraints<T> {
    return super.constraints;
  }

  constructor(attributes: Partial<T>) {
    super(attributes);

    makeObservable(this);
  }

  public getTemplatePreviewUrl(): string {
    return `${POLO_URL}view_email?cpn_id=${this.get('id')}&t_id=${this.get('template_id')}`;
  }
}
