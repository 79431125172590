import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { Collection, Model } from '@feathr/rachis';

export enum ErrorTag {
  AdAccountNotInBusiness = 'ad_account_not_in_business',
  BudgetTooLow = 'budget_too_low',
  EmailListCustomAudTos = 'email_list_custom_audience_tos_not_accepted',
  NDPNotAccepted = 'non_discrimination_policy_not_accepted',
  UnauthorizedAdTypeUse = 'unauthorized_ad_type_use',
  Unknown = 'unknown_error',
  UnsupportedRequest = 'unsupported_request',
}

export interface IUpsyncError {
  tag: ErrorTag;
  date: string;
  error: string;
  message?: string;
  link?: string;
}

export interface IErrorData extends Partial<IUpsyncError> {
  message: string;
  link?: string;
}

export interface IFacebookCampaignIntegration extends IBaseAttributes {
  feathr_oid: string;
  upsync_errors: IUpsyncError[];
}

export class FacebookCampaignIntegration extends Model<IFacebookCampaignIntegration> {
  public readonly className = 'FacebookCampaignIntegration';

  public get constraints(): TConstraints<IFacebookCampaignIntegration> {
    return {
      feathr_oid: {
        presence: {
          allowEmpty: false,
        },
      },
    };
  }

  public getUpsyncErrorData(): IErrorData | IUpsyncError | undefined {
    const upsyncErrors = this.get('upsync_errors', []);
    if (upsyncErrors.length === 0) {
      return;
    }
    const lastError = upsyncErrors[upsyncErrors.length - 1];
    const error = errorTypeMap[lastError.tag];
    if (error) {
      return error;
    }
    if (lastError.message) {
      return lastError;
    }
    return;
  }
}

export class FacebookCampaignIntegrations extends Collection<FacebookCampaignIntegration> {
  public getModel(attributes: Partial<IFacebookCampaignIntegration>): FacebookCampaignIntegration {
    return new FacebookCampaignIntegration(attributes);
  }

  public getClassName(): string {
    return 'facebook_campaign_integrations';
  }

  public override url(): string {
    return `${this.getHostname()}integrations/facebook/campaign/`;
  }
}

export const errorTypeMap: Partial<Record<ErrorTag, IErrorData>> = {
  [ErrorTag.BudgetTooLow]: {
    message: `Your campaign is experiencing budget issues. Support has been notified and will reach out shortly.`,
  },
  [ErrorTag.NDPNotAccepted]: {
    message:
      'Before you can run ads on Meta you must certify compliance with their non-discrimination policy.',
    link: 'https://facebook.com/certification/nondiscrimination',
  },
  [ErrorTag.UnsupportedRequest]: {
    message:
      'You do not have permission to make this action. A possible reason is your selected Ad Account is different than the one used to create this campaign.',
    link: '/settings/integrations/meta',
  },
  [ErrorTag.UnauthorizedAdTypeUse]: {
    message:
      "One or more ads in this campaign have been rejected.  Please review this campaign's ads to resolve the issue.",
  },
};
