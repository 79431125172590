import type { JSX } from 'react';
import React from 'react';

import { FieldDataType } from '@feathr/blackbox';

import DraggableFormElement from './DraggableFormElement';

import * as styles from './Builder.css';

function Builder(): JSX.Element {
  const fields = [
    { type: FieldDataType.str, label: 'Email address', value: 'sauron@mordor.com' },
    { type: FieldDataType.int, label: 'Inventory', value: 10 },
    { type: FieldDataType.date, label: 'Ring Last Seen', value: new Date().toISOString() },
  ];
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        {fields.map((field) => (
          <DraggableFormElement field={field} key={field.label} />
        ))}
      </div>
    </div>
  );
}

export default Builder;
