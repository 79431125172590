import type { TConstraints } from '@feathr/rachis';

import { AutoPinpointEmailBaseCampaign } from './auto_pinpoint_email_base';
import type { IDripStepCampaign } from './types';

export class DripStepCampaign extends AutoPinpointEmailBaseCampaign<IDripStepCampaign> {
  public override get constraints(): TConstraints<IDripStepCampaign> {
    return {
      ...super.constraints,
      set_action: {
        presence: {
          allowEmpty: false,
          message: "^Step targeting can't be blank",
        },
      },
    };
  }
}
