import { createContext } from 'react';

import {
  Accounts,
  AggregatedStats,
  Breadcrumbs,
  Campaigns,
  Conversions,
  Creatives,
  DailyStats,
  Events,
  FacebookCampaignInsights,
  Flights,
  Goals,
  Partners,
  Persons,
  Redirects,
  Segments,
  Targetings,
  Templates,
} from '@feathr/blackbox';

export enum EReportFlavors {
  ad_click = 'ad_click',
  ad_view = 'ad_view',
  email_link_click = 'email_link_click',
  email_view = 'email_view',
  page_link_click = 'page_link_click',
  page_view = 'page_view',
  pinpoint_tracked_email_click = 'pinpoint_tracked_email_click',
  pinpoint_tracked_email_complaint = 'pinpoint_tracked_email_complaint',
  pinpoint_tracked_email_delivered = 'pinpoint_tracked_email_delivered',
  pinpoint_tracked_email_hardbounce = 'pinpoint_tracked_email_hardbounce',
  pinpoint_tracked_email_open = 'pinpoint_tracked_email_open',
  pinpoint_tracked_email_send = 'pinpoint_tracked_email_send',
  pinpoint_tracked_email_softbounce = 'pinpoint_tracked_email_softbounce',
  pinpoint_tracked_email_suppression = 'pinpoint_tracked_email_suppression',
  email_preferences_update = 'email_preferences_update',
}

export class ReportStore {
  public Accounts = new Accounts([]);

  public AggregatedStats = new AggregatedStats([]);

  public Breadcrumbs = new Breadcrumbs([]);

  public Campaigns = new Campaigns([]);

  public Conversions = new Conversions([]);

  public Creatives = new Creatives([]);

  public Events = new Events([]);

  public FacebookCampaignInsights = new FacebookCampaignInsights([]);

  public Flights = new Flights([]);

  public Goals = new Goals([]);

  public Partners = new Partners([]);

  public Persons = new Persons([]);

  public Redirects = new Redirects([]);

  public Segments = new Segments([]);

  public Stats = new DailyStats([]);

  public Targetings = new Targetings([]);

  public Templates = new Templates([]);
}

export function createStore(): ReportStore {
  return new ReportStore();
}

export const StoresContext = createContext(new ReportStore());

export default createStore;
