import type { TConstraints } from '@feathr/rachis';

import { SmartPinpointEmailBaseCampaign } from './smart_pinpoint_email_base';
import type { ISmartPinpointEmailCampaign } from './types';

export class SmartPinpointEmailCampaign extends SmartPinpointEmailBaseCampaign<ISmartPinpointEmailCampaign> {
  /**
   * Smart Send campaigns using an email address that does not belong to a verified Email Domain
   * will be limited to <= 1000 sends
   */
  public override get constraints(): TConstraints<ISmartPinpointEmailCampaign> {
    return {
      ...super.constraints,
      send_all: {
        presence: {
          allowEmpty: false,
        },
      },
    };
  }
}
