import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { MenuListComponentProps } from 'react-select';
import { components } from 'react-select';

import type { Segment } from '@feathr/blackbox';
import { Button } from '@feathr/components';

import * as styles from './SegmentMenuList.css';

interface IProps {
  /** When true, the button to create a group is hidden. Only pre-existing options are shown. */
  hideCreate: boolean;
  onClick: () => Promise<void>;
}

function SegmentMenuList(
  props: IProps,
): (optionProps: MenuListComponentProps<Segment>) => JSX.Element {
  const { t } = useTranslation();

  return (optionProps): JSX.Element => {
    const { children, ...otherProps } = optionProps;
    const { hideCreate, onClick } = props;
    return (
      <components.MenuList {...otherProps} className={styles.root}>
        {children}
        {!hideCreate && (
          <div className={styles.footer}>
            <Button isFullWidth={true} onClick={onClick} prefix={<FontAwesomeIcon icon={faPlus} />}>
              {t('Create new group')}
            </Button>
          </div>
        )}
      </components.MenuList>
    );
  };
}

export default SegmentMenuList;
