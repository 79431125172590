import { faCog } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { Conversion, ICampaignAttributes, IEvent, IFlight } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Button, Checkbox, Label, Popover, Toolbar, Tooltip } from '@feathr/components';
import type { DisplayModel } from '@feathr/rachis';

import * as styles from './ConversionTableCardToolbar.css';

export interface IConversionTableCardToolbarProps {
  localUrl: ((url: string) => string) | undefined;
  model: DisplayModel<ICampaignAttributes | IFlight | IEvent>;
  toggleExportModal: () => void;
  toggleRerunModal: () => void;
  columnIds: string[];
  updateColumnIds: (columnIds: string[]) => void;
  columns: Array<IColumn<Conversion>>;
}

function ConversionTableCardToolbar({
  columns,
  columnIds,
  localUrl,
  model,
  toggleExportModal,
  toggleRerunModal,
  updateColumnIds,
}: IConversionTableCardToolbarProps): JSX.Element {
  const getColumnSetter = (columnId: string) => {
    return (): void => {
      const newColumnIds = [...columnIds];
      const index = newColumnIds.indexOf(columnId);
      const columnVisible = index >= 0;
      if (columnVisible) {
        newColumnIds.splice(index, 1);
      } else {
        newColumnIds.push(columnId);
      }
      updateColumnIds(newColumnIds);
    };
  };

  const isRecalculating = model.get('rerun', false);
  const RecalculateButton = (
    <Button disabled={isRecalculating} onClick={toggleRerunModal}>
      Recalculate conversions
    </Button>
  );

  return (
    <Toolbar>
      <Button onClick={toggleExportModal}>Export to CSV</Button>
      {localUrl &&
        (isRecalculating ? (
          <Tooltip title={'Conversions are currently being recalculated. Check back later.'}>
            {RecalculateButton}
          </Tooltip>
        ) : (
          <>{RecalculateButton}</>
        ))}
      <Tooltip title={'Customize columns'}>
        <Popover toggleOnClick={false}>
          <Button type={'icon-outlined'}>
            <FontAwesomeIcon icon={faCog}></FontAwesomeIcon>
          </Button>
          <div className={styles.content}>
            <section>
              <Label>Columns</Label>
              <div className={styles.columns}>
                {columns.map((column) => {
                  return (
                    <Checkbox
                      id={`checkbox-${column.id}`}
                      key={column.id}
                      label={typeof column.checkboxLabel !== 'function' && column.checkboxLabel}
                      name={column.id}
                      onChange={getColumnSetter(column.id!)}
                      value={columnIds.includes(column.id!)}
                    />
                  );
                })}
              </div>
            </section>
          </div>
        </Popover>
      </Tooltip>
    </Toolbar>
  );
}

export default observer(ConversionTableCardToolbar);
