import type { JSX } from 'react';
import React from 'react';

import Builder from './Builder';
import ConfigurationPanel from './ConfigurationPanel';

import * as styles from './FormEditor.css';

function FormEditor(): JSX.Element {
  return (
    <div className={styles.root}>
      <ConfigurationPanel />
      <Builder />
    </div>
  );
}

export default FormEditor;
