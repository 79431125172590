import { simpleHash } from '@feathr/hooks';
import type {
  IBaseAttributes,
  IGetParams,
  IWretchResponseError,
  IWretchResponseValid,
  TConstraints,
} from '@feathr/rachis';
import { isWretchError } from '@feathr/rachis';
import { Collection, Model, wretch } from '@feathr/rachis';

export interface IFacebookCampaignInsights extends IBaseAttributes {
  reach: number;
  unique_clicks: number;
}

export interface IFacebookCampaignInsightsParams extends IGetParams<IFacebookCampaignInsights> {
  date_start: string;
  date_end: string;
  campaign_id: string;
}

interface IFacebookCampaignInsightsSyncResponse extends Record<string, unknown> {
  response: number;
}

export class FacebookCampaignInsight extends Model<IFacebookCampaignInsights> {
  public readonly className = 'FacebookCampaignInsight';

  public get constraints(): TConstraints<IFacebookCampaignInsights> {
    return {};
  }
}

export class FacebookCampaignInsights extends Collection<FacebookCampaignInsight> {
  public getModel(attributes: Partial<IFacebookCampaignInsights>): FacebookCampaignInsight {
    return new FacebookCampaignInsight(attributes);
  }

  public getClassName(): string {
    return 'campaigns_insights';
  }

  public override url(): string {
    return `${this.getHostname()}integrations/facebook/campaign_insights`;
  }

  public getStats(
    params: IFacebookCampaignInsightsParams,
    callback?: (model: FacebookCampaignInsight) => void,
  ): FacebookCampaignInsight {
    const id = simpleHash(this.paramsToUrl(params).replace(this.url(), '').replace(/^\?/, ''));
    return super.get(id, {}, params, {}, callback);
  }

  public async sync(
    params: URLSearchParams,
  ): Promise<IWretchResponseValid<IFacebookCampaignInsightsSyncResponse> | IWretchResponseError> {
    const headers = this.getHeaders();
    const url = `${this.url()}/sync?${params.toString()}`;
    return wretch<IFacebookCampaignInsightsSyncResponse>(url, {
      method: 'GET',
      headers,
    });
  }

  public override async fetchModel(
    model: FacebookCampaignInsight,
    params: IFacebookCampaignInsightsParams,
    fetchOptions: Partial<RequestInit> = {},
    callback?: (model: FacebookCampaignInsight) => void,
  ): Promise<FacebookCampaignInsight> {
    const url = this.paramsToUrl(params);
    model.isPending = true;
    const response = await wretch<IFacebookCampaignInsights>(url, {
      method: 'GET',
      headers: this.getHeaders(),
      ...fetchOptions,
    });
    if (isWretchError(response)) {
      this.processErrorResponse(model, response.error);
      return model;
    }
    const out = this.processJSONResponse({ ...response.data }, params.only);
    if (callback) {
      callback(out);
    }
    return out;
  }
}
