import { moment, TimeFormat } from '@feathr/hooks';
import type { TConstraints } from '@feathr/rachis';

import { PinpointEmailBaseCampaign } from './pinpoint_email_base';
import type { ISmartPinpointEmailBaseCampaign } from './types';

export abstract class SmartPinpointEmailBaseCampaign<
  T extends ISmartPinpointEmailBaseCampaign = ISmartPinpointEmailBaseCampaign,
> extends PinpointEmailBaseCampaign<T> {
  public override get constraints(): TConstraints<T> {
    return {
      ...super.constraints,
      date_send_end: {
        datetime: (value: string, attributes: Partial<ISmartPinpointEmailBaseCampaign>) => {
          if (attributes.date_send_start) {
            const dateStart = moment.utc(attributes.date_send_start, moment.ISO_8601);
            return {
              earliest: dateStart
                .add(23, 'hours')
                .add(59, 'minutes')
                .format(TimeFormat.isoDateTime),
              // Smart sends do not need to check for attributes.send_schedule === 'now' because it is not an option
              message: '^End date/time must be at least 24 hours after start time',
            };
          }
          return undefined;
        },
        presence: {
          allowEmpty: false,
          message: '^End date/time cannot be empty',
        },
      },
      name: {
        exclusion: {
          within: ['Unnamed Smart Delivery Campaign'],
          message: '^Change the default name for this campaign',
        },
        presence: {
          allowEmpty: false,
        },
      },
      delay_value: {
        presence: {
          allowEmpty: false,
        },
      },
      delay_unit: {
        presence: {
          allowEmpty: false,
        },
      },
      actions(...args: any[]) {
        const { actions } = args[1];
        if (actions.length < 1) {
          return {
            length: { minimum: 1, message: '^You must have at least one trigger activity' },
          };
        }

        // "Exists" and "not exists" do not need to have a value.
        const filteredActions = actions.filter(
          ({ comparison }) => !['exists', 'nexists'].includes(comparison),
        );

        // True and false are valid values, so we only want to check for undefined, empty string, and null.
        if (filteredActions.some(({ value }) => ['', undefined, null].includes(value))) {
          return {
            array: {
              value: {
                presence: { allowEmpty: false, message: '^Trigger activity cannot be blank' },
              },
            },
          };
        }

        return null;
      },
    };
  }

  public override getDefaults(): Partial<T> {
    return {
      ...super.getDefaults(),
      mode: 'match_all',
      actions: [
        /**
         * Defining the default actions like this allows us
         * to render the first PredicateInput in an unselected state.
         * This is necessary to show the user that both activity and
         * attribute predicates are available for selection.
         */
        {
          attr_against: undefined,
        },
      ],
    };
  }
}
