import { computed } from 'mobx';

import type { TConstraints } from '@feathr/rachis';

import { DisplayCampaign } from './display';
import { monetizationConstraints } from './monetization';
import type { TFacebookCampaign } from './types';
import { FacebookObjectiveType } from './types';

export const FacebookCampaignObjectiveMap = new Map<FacebookObjectiveType, string>([
  [FacebookObjectiveType.Reach_Legacy, 'Reach'],
  [FacebookObjectiveType.Traffic_Legacy, 'Traffic'],
  [FacebookObjectiveType.Reach, 'Reach'],
  [FacebookObjectiveType.Traffic, 'Traffic'],
]);

export class FacebookCampaign<
  T extends TFacebookCampaign = TFacebookCampaign,
> extends DisplayCampaign<T> {
  public override get constraints(): TConstraints<TFacebookCampaign> {
    return {
      ...super.constraints,
      facebook_page_id: {
        presence: {
          allowEmpty: false,
        },
      },
      publisher_platforms: {
        presence: {
          allowEmpty: false,
        },
      },
      objective_type: {
        presence: {
          allowEmpty: false,
        },
      },
      targeted_countries: {
        presence: {
          allowEmpty: false,
        },
      },
      monetization_value: () => {
        if (this.isMonetization) {
          return monetizationConstraints.monetization_value;
        }
        return null;
      },
    };
  }

  @computed
  public get objectiveType(): string | undefined {
    return FacebookCampaignObjectiveMap.get(this.get('objective_type'));
  }
}
