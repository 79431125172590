import { ObjectId } from 'bson';
import type { ObservableMap } from 'mobx';
import { values } from 'mobx';

import type {
  Attributes,
  IBaseAttributes,
  ICollectionStore,
  TConstraints,
  TRachisEmpty,
} from '@feathr/rachis';
import { isWretchError } from '@feathr/rachis';
import { Collection, Model, wretch } from '@feathr/rachis';

import type { Notification, Notifications } from './notifications';

export interface IUserNotification extends IBaseAttributes {
  account_id: string;
  is_global?: boolean;
  is_read: boolean;
  notification_id: string;
  user_id: string;
}

interface IStore extends ICollectionStore {
  Notifications: Notifications | undefined;
}

export class UserNotification extends Model<IUserNotification> {
  public readonly className = 'Notification';

  public override collection: UserNotifications<this> | null = null;

  public get constraints(): TConstraints<IUserNotification> {
    return {};
  }

  public async read(): Promise<this | false> {
    if (!this.get('is_read')) {
      this.set({ is_read: true });
      return this.patchDirty();
    }
    return false;
  }

  public async unread(): Promise<this | false> {
    if (this.get('is_read')) {
      this.set({ is_read: false });
      return this.patchDirty();
    }

    return false;
  }

  public get notification(): Notification {
    this.assertCollection(this.collection);
    return this.collection.getFromStore('Notifications').get(this.get('notification_id'));
  }
}

export class UserNotifications<
  Model extends UserNotification = UserNotification,
> extends Collection<Model> {
  public constructor(initialModels: Array<Partial<Attributes<Model>>>, store: IStore) {
    super(initialModels, store);
  }

  public override getFromStore(collection: 'Notifications'): Notifications {
    if (!this.store[collection]) {
      throw new Error('Notifications store is not set for this UserNotifications instance.');
    }
    return this.store[collection] as Notifications;
  }

  public getModel(attributes: Partial<Partial<Attributes<Model>>>): Model {
    return new UserNotification(attributes) as Model;
  }

  public override url(): string {
    return `${this.getHostname()}user-notifications/`;
  }

  public getClassName(): string {
    return 'user_notifications';
  }

  public async readAll(fetchOptions: Partial<RequestInit> = {}): Promise<void> {
    const latestUserNotification = values(this.modelsById as ObservableMap<string, Model>).reduce(
      (a, b) =>
        new ObjectId(a.get('notification_id')).getTimestamp().getTime() >=
        new ObjectId(b.get('notification_id')).getTimestamp().getTime()
          ? a
          : b,
    );

    const body = this.patchToString({ is_read: true });
    const response = await wretch<TRachisEmpty>(
      `${this.url()}?latest_notification_id=${latestUserNotification.get('notification_id')}`,
      {
        method: 'PATCH',
        body,
        headers: this.getHeaders(),
        ...fetchOptions,
      },
    );
    if (isWretchError(response)) {
      throw response.error;
    }
  }
}
