import debounce from 'debounce-promise';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { OptionProps, SingleValueProps } from 'react-select';
import { components } from 'react-select';

import type { Campaign, Targetable, Targeting } from '@feathr/blackbox';
import { Session, TargetableClass } from '@feathr/blackbox';
import { AsyncSelect } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import { DEFAULT_DEBOUNCE_WAIT } from '@feathr/hooks';
import { isWretchError, wretch } from '@feathr/rachis';

import * as styles from './AffinityTargetingDataSelect.css';

const getHeaders = (): HeadersInit => Session.getHeaders();

interface IProps {
  campaign: Campaign;
  targeting: Targeting;
}

interface IDataOption {
  ThirdPartyDataId: string;
  Name: string;
  BrandName: string;
  UniqueUserCount: number;
  FullPath: string;
  Description: string;
}

type TOptionProps = OptionProps<IDataOption>;
type TSingleValueProps = SingleValueProps<IDataOption>;

interface IDataProps {
  data: IDataOption;
}

// TODO: Move this function to targetables.ts
function getThirdPartyData(categoryId: number): (inputValue: string) => Promise<IDataOption[]> {
  return async (inputValue: string): Promise<IDataOption[]> => {
    const url = `${BLACKBOX_URL}targetables/thirdpartydata?cat=${categoryId}&term=${encodeURIComponent(
      inputValue || '',
    )}`;

    const headers = {
      'Content-Type': 'application/json',
      ...getHeaders(),
    };
    const response = await wretch<Record<'Result', IDataOption[]>>(url, {
      headers,
      method: 'GET',
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data.Result;
  };
}

function DataOption(props: TOptionProps): JSX.Element {
  const { t } = useTranslation();
  const { data } = props as IDataProps;
  return (
    <components.Option {...props}>
      <div>
        <div>
          <div>
            <strong>{data.Name}</strong>
            <span> | </span>
            <span>{t('{{count, number}} Person', { count: data.UniqueUserCount })}</span>
          </div>
          <div className={styles.path}>
            <p>{data.FullPath}</p>
            <p className={styles.description}>{data.Description}</p>
          </div>
        </div>
      </div>
    </components.Option>
  );
}

function DataSingleValue(props: TSingleValueProps): JSX.Element {
  const { t } = useTranslation();
  const { data } = props as IDataProps;
  return (
    <components.SingleValue {...props}>
      <div className={styles.single}>
        <div>
          <div>
            <strong>{data.Name}</strong>
            <span> | </span>
            <span>{t('{{count, number}} Person', { count: data.UniqueUserCount })}</span>
          </div>
        </div>
      </div>
    </components.SingleValue>
  );
}

function AffinityTargetingDataSelect({ campaign, targeting }: IProps): JSX.Element {
  const { Targetables } = useContext(StoresContext);
  const [, setSegmentQuery] = useState('');
  const { t } = useTranslation();

  const targetableId = targeting.get('target_data');
  let targetable: Targetable;
  if (targetableId) {
    targetable = Targetables.get(targetableId);
  } else {
    targetable = Targetables.create({
      _cls: TargetableClass.affinity,
      name: t('Affinity Targeting'),
      category_id: 468,
      partner: campaign.get('parent_kind') === 'partner' ? campaign.get('parent') : undefined,
    });
    targeting.set({ target_data: targetable.get('id') });
  }

  function getDataOptionValue({ ThirdPartyDataId }: IDataOption): string {
    return ThirdPartyDataId;
  }

  function handleDataSelect({
    Name,
    ThirdPartyDataId,
    BrandName,
    Description,
    UniqueUserCount,
    FullPath,
  }: IDataOption): void {
    targetable.set({
      name: Name,
      thirdparty_data_id: ThirdPartyDataId,
      thirdparty_data_name: Name,
      thirdparty_brand_name: BrandName,
      description: Description,
      unique_user_count: UniqueUserCount,
      full_path: FullPath,
    });
  }

  const debounced = debounce(
    getThirdPartyData(targetable.get('category_id')!),
    DEFAULT_DEBOUNCE_WAIT,
  );

  function validateAudience(): string | undefined {
    /*
     *  Inactive audiences are not shown in the dropdown. The Affinity audience is inactive if the
     *  audience was not changed by the user and audience_inactive is true
     */
    return !targetable.isAttributeDirty('thirdparty_data_id') && targetable.get('audience_inactive')
      ? t('Affinity audience unavailable. You must select a different audience.')
      : undefined;
  }

  return (
    <AsyncSelect<IDataOption>
      cacheOptions={true}
      components={{ Option: DataOption, SingleValue: DataSingleValue }}
      defaultOptions={true}
      getOptionValue={getDataOptionValue}
      helpText={t('Search for, and target, an affinity audience.')}
      key={targetable.get('category_id')}
      label={t('Affinity audience')}
      loadOptions={debounced}
      name={'affinity_audience_select'}
      onInputChange={setSegmentQuery}
      onSelectSingle={handleDataSelect}
      placeholder={t('Choose affinity audience')}
      required={true}
      validationError={validateAudience()}
      value={
        targetable.get('thirdparty_data_id')
          ? ({
              ThirdPartyDataId: targetable.get('thirdparty_data_id'),
              Name: targetable.get('thirdparty_data_name'),
              UniqueUserCount: targetable.get('unique_user_count'),
              FullPath: targetable.get('full_path'),
              Description: targetable.get('description'),
            } as IDataOption)
          : undefined
      }
    />
  );
}

export default observer(AffinityTargetingDataSelect);
