import { action, computed, makeObservable } from 'mobx';

import { concatPath, dev, flattenError, localTime, moment, TimeFormat } from '@feathr/hooks';
import type { Attributes, TConstraints } from '@feathr/rachis';

import { CAMPAIGN_MAX_BUDGET } from '../accounts';
import { REGEX_INPUT_VALIDATION } from '../global';
import { ReportModel } from '../model';
import type { Campaigns } from './campaigns';
import type { ICampaignAttributes } from './types';
import { CampaignClass, CampaignState } from './types';

const {
  Affinity,
  AutoPinpointEmail,
  Conversation,
  Drip,
  DripStep,
  EmailList,
  EmailListFacebook,
  Facebook,
  LandingPage,
  Lookalike,
  MobileGeoFenceRetargeting,
  MobileGeoFencing,
  PinpointEmail,
  Referral,
  Search,
  SeedSegment,
  Segment,
  SmartPinpointEmail,
  PinpointPartnerMessage,
  TrackedLink,
  GoogleAdsSmart,
} = CampaignClass;

const { Archived, Draft, Erroring, Published, Publishing, Stopped } = CampaignState;

export const CampaignLabelMap = new Map<CampaignClass, string>([
  [Affinity, 'Affinity'],
  [AutoPinpointEmail, 'Auto Send'],
  [Conversation, 'Conversation'],
  [Drip, 'Drip'],
  [DripStep, 'Drip Step'],
  [EmailList, 'Email Mapping'],
  [EmailListFacebook, 'Meta Email Mapping'],
  [Facebook, 'Meta Retargeting'],
  [GoogleAdsSmart, 'Google Ad Grants'],
  [LandingPage, 'Landing Page'],
  [Lookalike, 'Lookalike'],
  [MobileGeoFenceRetargeting, 'Historical Geofencing'],
  [MobileGeoFencing, 'Mobile Geofencing'],
  [PinpointEmail, 'Single Send'],
  [Referral, 'Invites'],
  [Search, 'Search Keyword'],
  [SeedSegment, 'Lookalike'],
  [Segment, 'Retargeting'],
  [SmartPinpointEmail, 'Smart Send'],
  [TrackedLink, 'Tracked Link'],
]);

export const leadTimeCampaigns = [
  EmailList,
  MobileGeoFenceRetargeting,
  MobileGeoFencing,
  Search,
  SeedSegment,
];

export function getMaxTargetValue(maxBudget = CAMPAIGN_MAX_BUDGET, isMonetization = false): number {
  if (!isMonetization) {
    return maxBudget;
  }

  /*
   * Budgets must be less than the max budget or the equivalent number of impressions
   * at a $5CPM for a Monetization campaign.The equivalent number of
   * impressions is $5 / 1000 imps.
   * $10,000 * 1000 imp / $5 = 2,000,000 impressions.
   */
  return (maxBudget * 1000) / 5;
}

export function getMinDaysAdvance(type: CampaignClass): number {
  if (leadTimeCampaigns.includes(type)) {
    return 5;
  } else if ([Referral, LandingPage].includes(type)) {
    return 0;
  }
  return 1;
}

export function getMinStartDate(type: CampaignClass): moment.Moment {
  const minDaysAdvance = getMinDaysAdvance(type);
  return moment.utc().add(minDaysAdvance, 'days').endOf('day');
}

export function getMinDuration(): moment.Duration {
  return moment.duration(3, 'days');
}

export const baseConstraints: TConstraints<ICampaignAttributes> = {
  date_start: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    datetime: (value: string, attributes: any) => {
      if (
        [
          LandingPage,
          PinpointEmail,
          SmartPinpointEmail,
          AutoPinpointEmail,
          Drip,
          DripStep,
        ].includes(attributes._cls)
      ) {
        return undefined;
      }
      if (attributes.state === Draft) {
        const minDaysAdvance = getMinDaysAdvance(attributes._cls);
        return {
          earliest: moment.utc().add(minDaysAdvance, 'days').format(TimeFormat.isoDate),
          message: `^Campaigns of this type must be made at least ${minDaysAdvance} day${
            minDaysAdvance > 1 ? 's' : ''
          } in advance.`,
        };
      }
      return true;
    },
    presence: (...args: any[]) => {
      const attributes = args[1];
      if (attributes._cls === LandingPage) {
        return undefined;
      }
      return {
        allowEmpty: false,
        message: '^Start date cannot be empty.',
      };
    },
  },
  date_end: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    datetime: (value: string, attributes: any) => {
      if (attributes._cls === LandingPage) {
        return undefined;
      }
      if (attributes.date_start) {
        const dateStart = moment.utc(attributes.date_start, TimeFormat.isoDateTime);
        const dateEnd = moment.utc(value, TimeFormat.isoDateTime);
        const minDuration = getMinDuration();

        if (dateEnd.isBefore(dateStart)) {
          return {
            earliest: dateStart.add(minDuration).format(TimeFormat.isoDateTime),
            message: '^End date must be after start date.',
          };
        } else {
          return {
            earliest: dateStart.add(minDuration).format(TimeFormat.isoDateTime),
            message: `Campaigns of this type must be at least ${minDuration.asDays()} days long.`,
          };
        }
      }

      return true;
    },
    presence: (_, attributes) => {
      if (attributes._cls === LandingPage) {
        return undefined;
      }
      return {
        allowEmpty: false,
        message: '^End date cannot be empty.',
      };
    },
  },
  state: {
    inclusion: {
      within: [Archived, Draft, Erroring, Published, Publishing, Stopped],
      message: '^The campaign is not in a valid state.',
    },
  },
  name: {
    exclusion: {
      within: ['New Draft Campaign'],
      message: '^Change the default name for this campaign.',
    },
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: REGEX_INPUT_VALIDATION,
      flags: 'i',
      message: '^Campaign names must consist of letters, numbers, and basic symbols.',
    },
  },
};

export abstract class Campaign<
  IAttributes extends ICampaignAttributes = ICampaignAttributes,
> extends ReportModel<IAttributes> {
  public reportKey = 'c' as const;
  public className = 'campaign' as const;
  public override collection: Campaigns<this> | null = null;

  public get constraints(): TConstraints<IAttributes> {
    return baseConstraints;
  }

  constructor(attributes: Partial<IAttributes> = {}) {
    super(attributes);

    makeObservable(this);
  }

  public override set(patch: Partial<Attributes<this>>, dirty = true): void {
    super.set(patch, dirty);
  }

  public getItemUrl(pathSuffix?: string): string {
    const localPathSuffix = pathSuffix ?? (this.get('_cls') === Conversation ? 'edit' : undefined);

    const flightId = this.get('flight');
    if (flightId) {
      return concatPath(
        `/projects/${this.get('event')}/flights/${flightId}/campaigns/${this.id}`,
        localPathSuffix,
      );
    }
    // Drip Step campaigns should route to the Drip campaign page
    const id = this.get('_cls') === DripStep ? this.get('drip_campaign') : this.id;

    return concatPath(`/projects/${this.get('event')}/campaigns/${id}`, localPathSuffix);
  }

  public override getDefaults(): Partial<IAttributes> {
    return {
      ...super.getDefaults(),
      date_created: moment.utc(),
      date_start: moment.utc(),
      date_last_modified: moment.utc(),
      exposure_settings: {
        target_value: 0,
      },
      exposure_model: {
        kind: 'flat',
        freq_cap: 9,
        freq_cap_users: 0,
        freq_period: 480,
      },
      is_enabled: true,
      name: '',
      rerun: false,
      state: 'draft',
      total_stats: { flavors: {}, spend: 0 },
    } as Partial<IAttributes>;
  }

  @action.bound
  public async publish(validate = true): Promise<Campaign> {
    if (!this.collection) {
      throw new Error('Campaign is not in a collection.');
    }
    if (validate && !this.isValid(['_cls', ...Object.keys(this.constraints)], false)) {
      if (dev.isActive) {
        const errors = this.validate([], false, 'grouped').errors;
        const flattenedErrors = Object.entries(errors).reduce<string[]>((acc, [key, error]) => {
          const keyErrors = flattenError(error);
          return keyErrors.length ? [...acc, `${key}: ${keyErrors.join('; ')}`] : acc;
        }, []);

        dev.error(`${this.constructor.name} is invalid`, flattenedErrors.join('\n'));
      }
      throw new Error('Campaign is invalid');
    }
    return this.collection.publish(this);
  }

  public async stop(): Promise<Campaign> {
    const patch = {
      state: this.get('_cls') === PinpointPartnerMessage ? Draft : Stopped,
    } as Partial<Attributes<this>>;
    this.set(patch);
    return this.patch(patch);
  }

  public override get reportRangeStart(): string {
    /*
     * date_send_start has a timestamp for the exact time emails should go out,
     * whereas date_start is generally only used as a representation for the date itself,
     * removed from the time
     */
    const dateStart = this.isEmail ? this.get('date_send_start') : this.get('date_start');
    const dateCreated = this.get('date_created');
    if (dateStart) {
      return dateStart;
    }
    if (dateCreated) {
      return dateCreated;
    }
    return moment.utc().format(TimeFormat.isoDate);
  }

  @computed
  public get localStartTime(): string {
    return localTime(this.get('date_start'));
  }

  @computed
  public get localEndTime(): string {
    return localTime(this.get('date_end'));
  }

  @computed
  public get isCreativesCampaign(): boolean {
    return [
      Affinity,
      EmailList,
      EmailListFacebook,
      Facebook,
      Lookalike,
      MobileGeoFenceRetargeting,
      MobileGeoFencing,
      Search,
      SeedSegment,
      Segment,
    ].includes(this.get('_cls'));
  }

  @computed
  public get isAwarenessCampaign(): boolean {
    return [Affinity, Search, Lookalike, MobileGeoFenceRetargeting, MobileGeoFencing].includes(
      this.get('_cls'),
    );
  }

  /**
   * Getter that returns whether or not the campaign is an ad campaign.
   * Uses campaign.isCreativesCampaign, but is written in this way
   * for clarity in other parts of the app.
   */
  @computed
  public get isAdCampaign(): boolean {
    return this.isCreativesCampaign;
  }

  @computed
  public get isSegmentCampaign(): boolean {
    return [Segment, Facebook].includes(this.get('_cls'));
  }

  @computed
  public get isLandingPageCampaign(): boolean {
    return [LandingPage].includes(this.get('_cls'));
  }

  @computed
  public override get reportRangeEnd(): string {
    const dateEnd = this.isEmail ? this.get('date_send_end') : this.get('date_end');
    const momentEnd = moment.utc(dateEnd);
    const stats = this.get('total_stats');
    const isConverting = stats.conversions && (stats.conversions.full?.num || 0);
    if (dateEnd && isConverting) {
      return momentEnd.add(30, 'days').format(TimeFormat.isoDate);
    }
    if (dateEnd) {
      return dateEnd;
    }
    return moment.utc().format(TimeFormat.isoDate);
  }

  @computed
  public get totalViews(): number {
    const {
      flavors: { ad_view: adViews = 0, page_view: pageViews = 0, email_view: emailViews = 0 },
    } = this.get('total_stats') || {};
    return adViews + pageViews + emailViews;
  }

  @computed
  public get totalClicks(): number {
    const {
      flavors: {
        ad_click: adClick = 0,
        page_link_click: pageClick = 0,
        email_link_click: emailClick = 0,
      },
    } = this.get('total_stats') || {};
    return adClick + pageClick + emailClick;
  }

  @computed
  public get name(): string {
    const label = CampaignLabelMap.get(this.get('_cls'));
    return (
      this.get('name', '' as Exclude<IAttributes['name'], undefined>).trim() ||
      `Unnamed ${label ? label + ' ' : ''}Campaign`
    );
  }

  @computed
  public get isMonetization(): boolean {
    return (
      this.get('parent_kind') === 'partner' &&
      this.get('exposure_settings').target_type === 'fixed_impressions'
    );
  }

  @computed
  public get isFacebook(): boolean {
    return [Facebook, EmailListFacebook].includes(this.get('_cls'));
  }

  @computed
  public get isGoogle(): boolean {
    return this.get('_cls') === GoogleAdsSmart;
  }

  @computed
  public get isSingleSend(): boolean {
    return this.get('_cls') === PinpointEmail;
  }

  @computed
  public get isEmail(): boolean {
    return [PinpointEmail, SmartPinpointEmail, AutoPinpointEmail, Drip, DripStep].includes(
      this.get('_cls'),
    );
  }

  /**
   * Getter that checks if a campaign was created after the date
   * scripted updates was merged in. Campaigns that were live before
   * and after the merge may have statistical discrepancies across
   * report components due to changes in calculation methodology.
   */
  @computed
  public get isCreatedAfterScriptedUpdates(): boolean {
    return moment(this.get('date_start')).isAfter(moment('02-21-2023', 'MM-DD-YYYY'));
  }

  public isAfterDateSendStart({
    now = moment.utc(),
    offset = 0,
    offsetUnit = 'seconds',
  }: {
    now?: moment.Moment;
    offset?: number;
    offsetUnit?: moment.unitOfTime.DurationConstructor;
  } = {}): boolean {
    const sendStart = moment.utc(this.get('date_send_start'), moment.ISO_8601);
    return now.isSameOrAfter(sendStart.add(offset, offsetUnit));
  }

  /**
   * Getter that checks if an email campaign is past its end date
   * We offset 1 hour by default to allow time for the num_targeted stat
   * to finish processing before we display it.
   */
  public get isAfterDateSendEnd(): boolean {
    const now = moment.utc();
    const sendEnd = this.get('date_send_end');
    if (!sendEnd) {
      return false;
    }
    const sendEndMoment = moment.utc(sendEnd, moment.ISO_8601);
    return now.isSameOrAfter(sendEndMoment.add(1, 'hour'));
  }

  /**
   * Getter that checks if a non-email campaign is past its end date.
   */
  public get isAfterDateEnd(): boolean {
    const now = moment.utc();
    const end = this.get('date_end');
    if (!end) {
      return false;
    }
    return now.isSameOrAfter(end);
  }

  /**
   * Getter that checks if a non-email campaign is past its start date
   */
  public get isAfterDateStart(): boolean {
    const now = moment.utc();
    const start = this.get('date_start');
    if (!start) {
      return false;
    }
    return now.isSameOrAfter(start);
  }

  /**
   * Getter that checks if any campaign type is past its end date.
   */
  public get isPastEndDate(): boolean {
    return this.isEmail ? this.isAfterDateSendEnd : this.isAfterDateEnd;
  }

  public get isPastStartDate(): boolean {
    return this.isEmail ? this.isAfterDateSendStart() : this.isAfterDateStart;
  }
}

export class BaseCampaign extends Campaign<ICampaignAttributes> {}
